import React from "react";
import RequestSvg from "../../Svgs/RequestSvg";
import Items from "../../../utils/dummyData/Dummydata";

export default function CustomerQuery({ query }) {
  const { Tabledata } = Items;
  return (
    <>
      <div className="w-[100px] h-[23px] bg-white absolute top-[-10px] right-8 rounded flex justify-around items-center p-2">
        <RequestSvg />
        <p className="text-black text-[8px] font-bold">{query}</p>
      </div>
    </>
  );
}
