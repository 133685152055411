import React, { useEffect, useState } from "react";
import loginBG from "../../Assets/Images/login-bg.webp";
import InputField from "../../Components/Common/InputField";
import SingleUserSvg from "../../Components/Svgs/SingleUserSvg";
import PasswordSvg from "../../Components/Svgs/PasswordSvg";
import EyeClosedSvg from "../../Components/Svgs/EyeClosedSvg";
import EyeOpenSvg from "../../Components/Svgs/EyeOpenSvg";
// import EyeOpenSvg from "../../Assets/Images/eye-on.png";

export default function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  return (
    <>
      <div className="absolute top-0 left-0 w-screen h-screen overflow-hidden">
        <div className="relative">
          <div className="absolute bg-lightGreenBlur rounded-full w-[50%] h-[90vh] -top-[60vh] -left-[25%] opacity-20 filter blur-3xl overflow-hidden"></div>
          <div className="absolute bg-lightBlueBlur rounded-full w-[70%] h-[135vh] top-[65vh] left-[10%] opacity-10 filter blur-3xl overflow-hidden"></div>
          <div className="absolute bg-lightGreenBlur h-[90vh] w-[50%] top-[6vh] left-[85%] rounded-full filter blur-3xl opacity-30 overflow-hidden"></div>
        </div>
      </div>
      <div className="h-screen flex w-screen">
        <div className="w-1/2 flex justify-center">
          <div className="w-3/5 align-middle flex my-auto flex-col gap-4 z-10">
            <div>
              <h1 className="font-bold text-3xl mb-1"> Sign in</h1>
              <p className="text-textGrey">
                Please login to continue to your account.
              </p>
            </div>
            <InputField
              type={"text"}
              placeholder={"Email here"}
              name="email"
              label={"Email"}
              firstSvg={<SingleUserSvg />}
            />

            <InputField
              type={passwordVisible ? "text" : "password"}
              // type={""}
              placeholder={"Password here"}
              name="password"
              label={"Password"}
              firstSvg={<PasswordSvg />}
              secondSvg={
                passwordVisible ? (
                  <EyeOpenSvg
                    togglePasswordVisibility={togglePasswordVisibility}
                  />
                ) : (
                  <EyeClosedSvg
                    togglePasswordVisibility={togglePasswordVisibility}
                  />
                )
              }
            />
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  className="border-lightGreenBlur rounded-md text-lightGreenBlur focus:ring-lightGreenBlur checked:bg-lightGreenBlur focus:bg-lightGreenBlur ring-1 ring-inset"
                />{" "}
                <label className="text-sm">Keep me logged in</label>
              </div>

              <p className="text-sm text-textBlue underline font-semibold cursor-pointer">
                Forgot Password
              </p>
            </div>

            <div>
              <button className="w-full text-center border-white border rounded-3xl bg-bgBlack font-bold py-3 mt-4 text-white">
                Sign in
              </button>
            </div>
          </div>
        </div>
        <div className="w-1/2 py-2 px-4">
          <img
            src={loginBG}
            className="h-full  w-full  rounded-3xl whiteGradientBorder"
          />
        </div>
      </div>
    </>
  );
}
