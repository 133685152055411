import React, { useEffect, useState } from "react";
import SearchIcon from "../Svgs/SearchIcon";
import BellIcon from "../Svgs/BellIcon";
import avatar from "../../Assets/Images/avatarjpg.jpg";

export default function SearchBar() {
  // const [name, setterFunction] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);
  // const functionName = () => {
  //   setterFunction(!name);
  // };

  return (
    <div className="flex gap-4 items-center ">
      <div className="bg-bgBlack h-fit flex gap-4 items-center py-2 px-4 border border-white border-opacity-20 rounded-2xl ">
        <SearchIcon />
        {/* <p className="text-sm"> Search</p> */}
        <input
          className="bg-transparent placeholder:text-white outline-none"
          type="text"
          placeholder="Search"
        />
      </div>
      <div className="p-3 rounded-2xl bg-bgBlack">
        <BellIcon />
      </div>
      <div>
        <img src={avatar} className="rounded-full w-[40px] h-[40px] " />
      </div>
    </div>
  );
}
