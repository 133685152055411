import React from "react";

export default function ClockSvg() {
  return (
    <>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1112_9352)">
          <path
            d="M7.00352 14.1496C3.33664 14.1496 0.353516 11.1665 0.353516 7.49961C0.353516 3.83273 3.33664 0.849609 7.00352 0.849609C10.6704 0.849609 13.6535 3.83273 13.6535 7.49961C13.6535 11.1665 10.6704 14.1496 7.00352 14.1496ZM7.00352 1.48297C3.68594 1.48297 0.986876 4.18203 0.986876 7.49961C0.986876 10.8172 3.68594 13.5162 7.00352 13.5162C10.3211 13.5162 13.0202 10.8172 13.0202 7.49961C13.0202 4.18203 10.3211 1.48297 7.00352 1.48297Z"
            fill="url(#paint0_linear_1112_9352)"
          />
          <path
            d="M10.0994 7.81669L7.0032 7.81669C6.91921 7.81669 6.83867 7.78333 6.77928 7.72394C6.71989 7.66455 6.68652 7.584 6.68652 7.50001L6.68652 2.96121C6.68652 2.87722 6.71989 2.79667 6.77928 2.73728C6.83867 2.6779 6.91921 2.64453 7.0032 2.64453C7.08719 2.64453 7.16774 2.6779 7.22713 2.73728C7.28652 2.79667 7.31988 2.87722 7.31988 2.96121L7.31988 7.18333L10.0994 7.18333C10.1834 7.18333 10.264 7.21669 10.3234 7.27608C10.3828 7.33547 10.4161 7.41602 10.4161 7.50001C10.4161 7.584 10.3828 7.66455 10.3234 7.72394C10.264 7.78333 10.1834 7.81669 10.0994 7.81669Z"
            fill="url(#paint1_linear_1112_9352)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1112_9352"
            x1="13.4072"
            y1="0.849609"
            x2="-2.13928"
            y2="4.15119"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00A7F7" />
            <stop offset="1" stop-color="#00FC92" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1112_9352"
            x1="10.3471"
            y1="2.64453"
            x2="5.89527"
            y2="3.32626"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#00A7F7" />
            <stop offset="1" stop-color="#00FC92" />
          </linearGradient>
          <clipPath id="clip0_1112_9352">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(0.00390625 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
