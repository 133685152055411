import React, { useState } from "react";
import ImageThumbnail from "../Svgs/ImageThumbnail";
import ImagePlus from "../Svgs/ImagePlus";

const ImagePicker = ({ imageId, imageState, imageSetterFunction }) => {
  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();
      reader.onload = () => {
        imageSetterFunction(reader.result);
      };
      reader.readAsDataURL(selectedImage);
    }
  };

  return (
    <div className="">
      <div className="relative h-16 w-16 bg-white bg-opacity-10 cursor-pointer flex items-center justify-center rounded-2xl">
        {imageState ? (
          <img
            src={imageState}
            alt="Selected Image"
            style={{ objectFit: "cover" }}
            className="object-cover object-center h-full w-full"
            onClick={() => document.getElementById(imageId).click()}
          />
        ) : (
          <div
            className="bgp-2"
            onClick={() => document.getElementById(imageId).click()}
          >
            <ImageThumbnail />
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          id={imageId}
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <div
          className={`bg-bgBlack p-1 rounded-full absolute -bottom-3 -right-3 z-10 cursor-pointer  ${
            imageState && "rotate-45"
          } `}
          onClick={() =>
            imageState
              ? imageSetterFunction(null)
              : document.getElementById(imageId).click()
          }
        >
          <ImagePlus />
        </div>
      </div>
    </div>
  );
};

export default ImagePicker;
