// export default function GradientButton({ buttonText, onClickFunction }) {
//   // const [name, setterFunction] = useState(false);
//   // console.log("passwordVisible>>>", passwordVisible);
//   // const functionName = () => {
//   //   setterFunction(!name);
//   // };

//   return (
//     <button
//       className="min-w-32 border border-white border-opacity-20 py-2 px-4 rounded-xl bg-gradient-to-r from-lightGreenBlur to-lightBlueBlur text-buttonBlackText font-semibold cursor-pointer"
//       onClick={onClickFunction}
//     >
//       {buttonText}
//     </button>
//   );
// }

export default function GradientButton({
  onClickFunction,
  buttonText,
  width,
  height,
  backgroundColor = " bg-gradient-to-r from-lightGreenBlur to-lightBlueBlur ",
  border = " rounded-xl ",
}) {
  const buttonClasses = `border border-white border-opacity-20 py-2 px-4 text-lg text-buttonBlackText font-semibold cursor-pointer mx-auto ${
    width || "min-w-32"
  } ${height || "h-auto"} ${backgroundColor} ${border}`;

  return (
    <button className={buttonClasses} onClick={onClickFunction}>
      {buttonText}
    </button>
  );
}
