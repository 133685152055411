import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateHeader } from "../../../store/slices/headerSlice";

import MiniHeader from "../../../Components/Layout/MiniHeader";
import ALS1 from "../../../Components/MultiStepForms/AddLocation/ALS1";
import ALS2 from "../../../Components/MultiStepForms/AddLocation/ALS2";
import ALS3 from "../../../Components/MultiStepForms/AddLocation/ALS3";
import ALS4 from "../../../Components/MultiStepForms/AddLocation/ALS4";
import ALReview from "../../../Components/MultiStepForms/AddLocation/ALReview";

export default function AddLocation() {
  const dispatch = useDispatch();
  const totalSteps = 5;
  const [currentStep, setCurrentStep] = useState(1);
  // console.log("currentStep>>>", currentStep);

  const increaseStep = () => {
    currentStep < totalSteps
      ? setCurrentStep((prev) => prev + 1)
      : addLocation();
  };

  const decreaseStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const addLocation = () => {
    console.log("addLocation>>>");
  };

  useEffect(() => {
    const headerData = {
      title: "Add Location",
      breadCrumbs: [
        {
          name: "manage",
          link: "/manage/locations",
        },
        {
          name: "locations",
          link: "/manage/locations",
        },
        {
          name: "add",
          link: "/manage/locations/add",
        },
      ],
    };

    dispatch(updateHeader(headerData));
  });

  return (
    <>
      <MiniHeader
        leftSideText={
          currentStep < totalSteps ? `Step 0${currentStep} - 04` : "Review"
        }
        nextText={currentStep === totalSteps ? "Submit" : "Next"}
        nextFunction={increaseStep}
        backText="Back"
        backButtonVisible={currentStep > 1}
        backFunction={decreaseStep}
      />
      <div className="mt-6">
        {currentStep === 1 && <ALS1 />}
        {currentStep === 2 && <ALS2 />}
        {currentStep === 3 && <ALS3 />}
        {currentStep === 4 && <ALS4 />}
        {currentStep === 5 && <ALReview />}
      </div>
    </>
  );
}
