export default function EyeOpenSvg({ togglePasswordVisibility }) {
  return (
    <svg
      class="w-6 h-6 text-white dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={togglePasswordVisibility}
    >
      <path
        stroke="currentColor"
        strokewidthidth="2"
        d="M21 12c0 1.2-4.03 6-9 6s-9-4.8-9-6c0-1.2 4.03-6 9-6s9 4.8 9 6Z"
      />
      <path
        stroke="currentColor"
        strokewidthidth="2"
        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
      />
    </svg>
  );
}
