import React from "react";

export default function SelectIconGreen() {
  return (
    <>
      <svg
        width="8"
        height="5"
        viewBox="0 0 8 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.91821 0.520183L3.95988 4.47852L0.0015453 0.520182L7.91821 0.520183Z"
          fill="#00FC92"
        />
      </svg>
    </>
  );
}
