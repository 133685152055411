import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RequestSvg from "../../../Components/Svgs/RequestSvg";
import TableDescription from "../../../Components/TableManagement/FloorManagement/TableDescription";
import Legends from "../../../Components/Modal/Legends";
import QuickRequest from "../../../Components/Modal/QuickRequest";
import CreatNewTable from "../CreateNewTable/CreatNewTable";
import EditTable from "../EditTable/EditTable";

export default function FloorManagement() {
  const params = useParams();
  console.log("params>>>>", params);

  const [showLegends, setShowLegends] = useState(false);
  const [showRequest, setShowRequest] = useState(false);
  const [showAddTable, setShowAddTable] = useState(false);
  const [showEditTable, setShowEditTable] = useState(false);

  function toggleEditTable() {
    setShowEditTable(true);
    setShowRequest(false);
    setShowLegends(false);
    setShowAddTable(false);
  }
  function toggleEditTableFalse() {
    setShowEditTable(false);
  }

  function toggleLegends() {
    setShowLegends(!showLegends);
    setShowRequest(false);
  }

  function toggleRequest() {
    setShowRequest(!showRequest);
    setShowLegends(false);
  }
  function toggleAddTable() {
    setShowAddTable(!showAddTable);
    setShowRequest(false);
    setShowLegends(false);
  }

  return (
    <>
      {showEditTable && (
        <EditTable
          showEditTable={showEditTable}
          setShowEditTable={setShowEditTable}
          toggleEditTableFalse={toggleEditTableFalse}
        />
      )}
      <div className="flex flex-col p-1">
        <div className="w-[100%] h-[10%] p-3 bg-bgBlack  border border-solid border-white border-opacity-20 rounded-3xl flex justify-between items-center">
          <div className="w-[50%]  sm:w-[50%]  lg:w-[25%] flex justify-between p-1 ">
            <button className="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur w-[48%] text-black font-bold h-[50px] border border-solid border-white border-opacity-20 rounded-3xl">
              Ground Floor
            </button>
            <button className="bg-bgBlack w-[48%] border border-solid border-white border-opacity-20 rounded-3xl text-white font-bold h-[50px] ">
              Add Floor
            </button>
          </div>
          <div className="lg:w-[10%] sm:w-[25%]">
            <button
              onClick={toggleAddTable}
              className="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur w-[98%] text-black font-bold h-[50px] border border-solid border-white border-opacity-20 rounded-3xl"
            >
              Add Table
            </button>
          </div>
        </div>
        <div className="w-[100%] h-auto mt-5 border-1 bg-bgBlack mx-auto border-solid border-white border-opacity-20 rounded-3xl">
          <div className="w-[99%] h-[10%] m-2 ">
            <div className="w-[100%] h-[99%] flex justify-start items-center md:grid md:grid-cols-3 lg:flex lg:justify-start lg:items-center border-b border-solid border-white border-opacity-20 pb-3 ">
              <div className="sm:w-[15%] xl:w-[7%]  text-white text-xs m-2 p-2 font-bold h-[50px] border border-solid border-white border-opacity-20 rounded-2xl flex items-center justify-between">
                <p>Total Tables</p>
                <p className="text-gradient-custom ">{13}</p>
              </div>
              <div className="sm:w-[15%] xl:w-[8%] text-white text-xs m-2 p-2 font-bold h-[50px] border-[3px] border-solid border-lightGreenBlur border-opacity-20 rounded-2xl flex items-center justify-between">
                <p>Available Tables</p>
                <p className="text-gradient-custom ">{13}</p>
              </div>
              <div className="sm:w-[15%] xl:w-[8%] text-white text-xs m-2 p-2 font-bold h-[50px] border-[3px]  border-solid border-yellowborder border-opacity-20 rounded-2xl flex items-center justify-between">
                <p>Occupied Tables</p>
                <p className="text-gradient-custom ">{13}</p>
              </div>
              <div className="sm:w-[15%] xl:w-[8%] text-white text-xs m-2 p-1 font-bold h-[50px] border-[3px]  border-solid border-redborder border-opacity-20 rounded-2xl flex items-center justify-between">
                <p>Disable Tables</p>
                <p className="text-gradient-custom ">{13}</p>
              </div>
              <div
                onClick={toggleRequest}
                className="sm:w-[15%] xl:w-[6%] text-white text-xs m-2 p-2 font-bold h-[50px] cursor-pointer border border-solid border-white border-opacity-20 rounded-2xl flex items-center justify-between"
              >
                <RequestSvg />
                <p>Request</p>
              </div>
              <div
                onClick={toggleLegends}
                className="sm:w-[15%] xl:w-[6%] text-black  text-xs m-2 font-bold h-[50px] cursor-pointer border border-solid border-white border-opacity-20 rounded-2xl flex items-center justify-center bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur"
              >
                <p>Legends</p>
              </div>
            </div>
            <div className="w-[99%] flex items-center mt-10 ">
              <div className="  flex-col justify-center">
                {showAddTable && (
                  <CreatNewTable
                    showAddTable={showAddTable}
                    setShowAddTable={setShowAddTable}
                  />
                )}
                {showLegends && (
                  <Legends
                    showLegends={showLegends}
                    setShowLegends={setShowLegends}
                  />
                )}
                {showRequest && (
                  <QuickRequest
                    showRequest={showRequest}
                    setShowRequest={setShowRequest}
                  />
                )}

                <TableDescription
                  toggleEditTable={toggleEditTable}
                  showEditIcon={true}
                  showEditTable={showEditTable}
                  setShowEditTable={setShowEditTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
