import React from 'react'

export default function AshTraySvg() {
    return (
        <>

        
<svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.9043 31.9998H6.43353C4.88733 31.9981 3.40497 31.3873 2.31164 30.3015C1.21831 29.2157 0.603314 27.7436 0.601562 26.2081V21.1556C0.601562 20.9875 0.668794 20.8263 0.788467 20.7075C0.90814 20.5886 1.07045 20.5219 1.2397 20.5219H12.4337C12.6029 20.5219 12.7652 20.5886 12.8849 20.7075C13.0046 20.8263 13.0718 20.9875 13.0718 21.1556C13.0724 21.6873 13.2854 22.197 13.6639 22.573C14.0425 22.949 14.5558 23.1605 15.0912 23.1611H20.344C20.8794 23.1605 21.3927 22.949 21.7712 22.573C22.1498 22.197 22.3628 21.6873 22.3634 21.1556C22.3634 20.9875 22.4306 20.8263 22.5503 20.7075C22.67 20.5886 22.8323 20.5219 23.0015 20.5219H34.0986C34.2679 20.5219 34.4302 20.5886 34.5499 20.7075C34.6695 20.8263 34.7368 20.9875 34.7368 21.1556V26.208C34.735 27.7436 34.12 29.2158 33.0265 30.3017C31.9331 31.3875 30.4506 31.9982 28.9043 31.9998ZM1.87783 21.7893V26.208C1.87923 27.4075 2.35965 28.5574 3.21371 29.4056C4.06777 30.2538 5.22571 30.7309 6.43353 30.7323H28.9043C30.1121 30.7309 31.2701 30.2538 32.1241 29.4056C32.9782 28.5574 33.4586 27.4075 33.46 26.208V21.7893H23.5777C23.4288 22.5326 23.0253 23.2017 22.4358 23.6829C21.8463 24.1641 21.1071 24.4276 20.344 24.4286H15.0912C14.3281 24.4275 13.589 24.1639 12.9996 23.6828C12.4101 23.2016 12.0067 22.5325 11.8579 21.7893H1.87783Z" fill="url(#paint0_linear_933_19724)"/>
<path d="M16.4897 24.8856C16.355 24.8856 16.2239 24.8433 16.1149 24.7648C16.006 24.6863 15.9248 24.5756 15.8831 24.4485C15.8413 24.3214 15.8411 24.1845 15.8824 24.0572C15.9237 23.93 16.0045 23.819 16.1132 23.7402L33.8291 10.9125C33.9597 10.8179 34.1216 10.776 34.2821 10.7954C34.4426 10.8148 34.5898 10.8939 34.6937 11.0169L37.1441 13.9138C37.2003 13.9802 37.2421 14.0574 37.2671 14.1405C37.292 14.2236 37.2995 14.3109 37.2891 14.397C37.2787 14.4831 37.2506 14.5662 37.2065 14.6411C37.1625 14.7159 37.1034 14.781 37.033 14.8323L27.0291 22.1235C26.8926 22.223 26.722 22.2645 26.5547 22.239C26.3875 22.2135 26.2373 22.123 26.1371 21.9875C26.037 21.852 25.9952 21.6826 26.0209 21.5165C26.0466 21.3504 26.1376 21.2012 26.2741 21.1018L35.7286 14.211L34.0997 12.2852L16.8653 24.7639C16.7563 24.8431 16.6247 24.8857 16.4897 24.8856Z" fill="url(#paint1_linear_933_19724)"/>
<path d="M32.3437 18.0971C32.2571 18.0973 32.1713 18.0799 32.0916 18.046C32.012 18.0121 31.9401 17.9624 31.8805 17.8999L29.2297 15.1275C29.1131 15.0057 29.0501 14.8428 29.0544 14.6747C29.0587 14.5067 29.1301 14.3472 29.2528 14.2314C29.3756 14.1157 29.5396 14.053 29.7088 14.0573C29.878 14.0616 30.0386 14.1325 30.1552 14.2544L32.8063 17.0266C32.8923 17.1165 32.9499 17.2294 32.9719 17.3514C32.9939 17.4735 32.9794 17.5992 32.9301 17.7132C32.8809 17.8271 32.7991 17.9241 32.6948 17.9924C32.5906 18.0606 32.4685 18.0969 32.3436 18.0969L32.3437 18.0971Z" fill="url(#paint2_linear_933_19724)"/>
<path d="M17.6694 15.1401C17.5001 15.1401 17.3378 15.0733 17.2182 14.9544C17.0985 14.8356 17.0312 14.6744 17.0312 14.5063V3.63617C17.0312 3.4681 17.0985 3.30691 17.2182 3.18806C17.3378 3.06921 17.5001 3.00244 17.6694 3.00244C17.8386 3.00244 18.0009 3.06921 18.1206 3.18806C18.2403 3.30691 18.3075 3.4681 18.3075 3.63617V14.5063C18.3075 14.6744 18.2403 14.8356 18.1206 14.9544C18.0009 15.0733 17.8386 15.1401 17.6694 15.1401Z" fill="url(#paint3_linear_933_19724)"/>
<path d="M22.4736 12.1377C22.3043 12.1377 22.142 12.0709 22.0224 11.9521C21.9027 11.8332 21.8354 11.6721 21.8354 11.504V0.633731C21.8354 0.465655 21.9027 0.304464 22.0224 0.185616C22.142 0.0667679 22.3043 0 22.4736 0C22.6428 0 22.8051 0.0667679 22.9248 0.185616C23.0445 0.304464 23.1117 0.465655 23.1117 0.633731V11.504C23.1117 11.6721 23.0445 11.8332 22.9248 11.9521C22.8051 12.0709 22.6428 12.1377 22.4736 12.1377Z" fill="url(#paint4_linear_933_19724)"/>
<path d="M13.038 12.1377C12.8688 12.1377 12.7065 12.0709 12.5868 11.9521C12.4671 11.8332 12.3999 11.6721 12.3999 11.504V0.633731C12.3999 0.550508 12.4164 0.468101 12.4485 0.391213C12.4805 0.314325 12.5276 0.244463 12.5868 0.185616C12.6461 0.126768 12.7164 0.0800876 12.7938 0.0482396C12.8713 0.0163916 12.9542 0 13.038 0C13.1218 0 13.2048 0.0163916 13.2822 0.0482396C13.3597 0.0800876 13.43 0.126768 13.4893 0.185616C13.5485 0.244463 13.5955 0.314325 13.6276 0.391213C13.6597 0.468101 13.6762 0.550508 13.6762 0.633731V11.504C13.6762 11.6721 13.6089 11.8332 13.4893 11.9521C13.3696 12.0709 13.2073 12.1377 13.038 12.1377Z" fill="url(#paint5_linear_933_19724)"/>
<defs>
<linearGradient id="paint0_linear_933_19724" x1="34.1046" y1="20.5219" x2="4.29494" y2="39.349" gradientUnits="userSpaceOnUse">
<stop stop-color="#00A7F7"/>
<stop offset="1" stop-color="#00FC92"/>
</linearGradient>
<linearGradient id="paint1_linear_933_19724" x1="36.8966" y1="10.7908" x2="13.178" y2="18.4536" gradientUnits="userSpaceOnUse">
<stop stop-color="#00A7F7"/>
<stop offset="1" stop-color="#00FC92"/>
</linearGradient>
<linearGradient id="paint2_linear_933_19724" x1="32.9091" y1="14.0571" x2="28.3072" y2="15.0073" gradientUnits="userSpaceOnUse">
<stop stop-color="#00A7F7"/>
<stop offset="1" stop-color="#00FC92"/>
</linearGradient>
<linearGradient id="paint3_linear_933_19724" x1="18.2839" y1="3.00244" x2="16.7255" y2="3.03724" gradientUnits="userSpaceOnUse">
<stop stop-color="#00A7F7"/>
<stop offset="1" stop-color="#00FC92"/>
</linearGradient>
<linearGradient id="paint4_linear_933_19724" x1="23.0881" y1="-4.59748e-07" x2="21.5297" y2="0.0347978" gradientUnits="userSpaceOnUse">
<stop stop-color="#00A7F7"/>
<stop offset="1" stop-color="#00FC92"/>
</linearGradient>
<linearGradient id="paint5_linear_933_19724" x1="13.6525" y1="-4.59748e-07" x2="12.0942" y2="0.0347978" gradientUnits="userSpaceOnUse">
<stop stop-color="#00A7F7"/>
<stop offset="1" stop-color="#00FC92"/>
</linearGradient>
</defs>
</svg>








        </>
    )
}
