import React, { useEffect, useState } from "react";

export default function InputField({
  label,
  type,
  value,
  onChangeFunction,
  name,
  placeholder,
  firstSvg,
  secondSvg,
}) {
  return (
    <div>
      <label className="font-semibold">{label}</label>
      <div className=" relative border-b border-white py-4 flex items-center justify-between">
        <div className="absolute">{firstSvg}</div>

        <input
          className="bg-inherit font-semibold ml-12 focus-visible:border-none focus-visible:outline-none w-full placeholder:font-normal"
          type={type}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChangeFunction}
        />
        <div className="mr-1 ml-4 cursor-pointer">{secondSvg}</div>
      </div>
    </div>
  );
}
