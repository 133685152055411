import React from "react";

export default function SeatCapacity() {
  return (
    <>
      <div className="bg-bgBlack  border border-solid border-white border-opacity-20 rounded-3xl px-2 py-6 w-[100%] h-fit">
        <h3 className="text-gradient-custom  text-xl font-bold pl-2  custom-font">
          Seat Capacity
        </h3>
        <h4 className="text-gray-200  text-lg font-semibold pl-2 pt-2 pb-3">
          Seat Capacity in Number
        </h4>

        <ul className=" w-fit text-sm font-medium bg-transparent p-4">
          <li className="w-full mb-2 bg-transparent border border-solid border-gray-200 border-opacity-20 rounded-full pr-4">
            <div className="flex items-center ps-3">
              <input
                id="green"
                name="color"
                color="green"
                type="radio"
                value=""
                class="w-6 h-6 "
              />
              <label
                for="list-radio-passport"
                className="w-full py-3 ms-2 text-sm font-medium"
              >
                2 Seats
              </label>
            </div>
          </li>
          <li className="w-full mb-2 bg-transparent border border-solid border-gray-200 border-opacity-20 rounded-full pr-4">
            <div className="flex items-center ps-3">
              <input
                id="green"
                name="color"
                color="green"
                type="radio"
                value=""
                class="w-6 h-6 "
              />
              <label
                for="list-radio-passport"
                className="w-full py-3 ms-2 text-sm font-medium"
              >
                4 Seats
              </label>
            </div>
          </li>
          <li className="w-full mb-2 bg-transparent border border-solid border-gray-200 border-opacity-20 rounded-full pr-4">
            <div className="flex items-center ps-3">
              <input
                id="green"
                name="color"
                color="green"
                type="radio"
                value=""
                class="w-6 h-6 "
              />
              <label
                for="list-radio-passport"
                className="w-full py-3 ms-2 text-sm font-medium"
              >
                6 Seats
              </label>
            </div>
          </li>
          <li className="w-full mb-2 bg-transparent border border-solid border-gray-200 border-opacity-20 rounded-full pr-4">
            <div className="flex items-center ps-3">
              <input
                id="greendot"
                name="color"
                color="green"
                type="radio"
                value=""
                class="w-6 h-6 "
              />
              <label
                for="list-radio-passport"
                className="w-full py-3 ms-2 text-sm font-medium"
              >
                Custom
              </label>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
