import React from 'react'

export default function TableDetails() {
  return (
    <>
       <div className='bg-bgBlack border border-solid border-white border-opacity-20 rounded-3xl px-2 py-6'>
                                <h3 className='text-gradient-custom  text-xl font-bold pl-2  custom-font'>Providing table details</h3>

                                <form className="max-w-sm ">
                                    <select name="" id="" className=' font-semibold bg-transparent border-b-[1px] border-solid border-white border-opacity-20 p-3 w-[95%]'>

                                        <option selected className='bg-bgBlack text-lg pt-2'>Select Floor</option>
                                        <option value="f1" className='bg-bgBlack'>1st Floor</option>
                                        <option value="f2" className='bg-bgBlack'>2nd Floor</option>
                                        <option value="f3" className='bg-bgBlack' >3rd Floor</option>
                                        <option value="ter" className='bg-bgBlack'>Terrace</option>
                                    </select>

                                    <div className='pt-8 mb-3  mx-3 font-semibold bg-transparent border-b-[1px] border-solid border-white border-opacity-20 '>
                                        <label for="first_name" className=" mb-2 text-lg font-semibold text-white py-3 w-[95%] ">Table Number</label><br />
                                        <input type="text" id="table-no" className='w-[100%] font-normal bg-transparent  py-4 px-3 text-gray-300 ' placeholder="T-00" required />
                                    </div>

                                </form>




                            </div>
    
    
    
    </>
  )
}
