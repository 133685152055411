// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.text-gradient-custom {
  background: linear-gradient(#00A7F7, #00FC92);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.custom-font {
  font-family: 'Urbanist', sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 36.4px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";;AAEA;EACE,6CAA6C;EAC7C,6BAA6B;EAC7B,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,mCAAmC;EACnC,gBAAgB;EAChB,eAAe;EACf,mBAAmB;AACrB","sourcesContent":["\n\n.text-gradient-custom {\n  background: linear-gradient(#00A7F7, #00FC92);\n  -webkit-background-clip: text;\n  background-clip: text;\n  color: transparent;\n}\n.custom-font {\n  font-family: 'Urbanist', sans-serif;\n  font-weight: 600;\n  font-size: 26px;\n  line-height: 36.4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
