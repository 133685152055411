import React, { useState } from "react";
import CrossSvg from "../Svgs/CrossSvg";
import RedDotSvg from "../Svgs/RedDotSvg";
import LikeSvg from "../Svgs/LikeSvg";
import DollarSvg from "../Svgs/DollarSvg";
import DoNotSvg from "../Svgs/DoNotSvg";
import YellowDotSvg from "../Svgs/YellowDotSvg";
import GreenDotSvg from "../Svgs/GreenDotSvg";

export default function Legends({showLegends,setShowLegends}) {

  const toggleVisibility = () => {
    setShowLegends(!showLegends);
  };

  return (
    <>
      {showLegends && (
        <div className="z-[2] w-screen h-screen fixed top-0 left-0 bg-[#000000C7] p-3 border border-solid border-white border-opacity-20 rounded-3xl ">
          <div className="bg-bgBlack w-[490px] h-[323px] border border-solid border-white border-opacity-20 rounded-3xl absolute right-10 top-40">
            <div className="flex justify-between m-5 border-b-[1px] border-solid border-white border-opacity-20 pb-4">
              <h2 className="custom-font">Legends</h2>
              <div onClick={toggleVisibility}   className=" cursor-pointer flex justify-end mt-5 mr-2">
                <CrossSvg />
              </div>
            </div>

            <div className="flex justify-between p-5 ">
              <div className="flex flex-col space-y-8 mt-5">
                <div className="flex">
                  <div className="mt-1">
                    <YellowDotSvg />
                  </div>
                  <p className="px-5 font-semibold text-lg font-family">
                    Booked/Occupied
                  </p>
                </div>
                <div className="flex">
                  <div className="mt-1">
                    <GreenDotSvg />
                  </div>
                  <p className="px-5 font-semibold text-lg font-family">
                    Available
                  </p>
                </div>
                <div className="flex">
                  <div className="mt-1">
                    <RedDotSvg />
                  </div>
                  <p className="px-5 font-semibold text-lg font-family">
                    Disabled
                  </p>
                </div>
              </div>

              <div className="flex flex-col space-y-8 mt-5">
                <div className="flex">
                  <div className="mt-1">
                    <LikeSvg />
                  </div>
                  <p className="px-5 font-semibold text-lg font-family">
                    Special Occasion
                  </p>
                </div>

                <div className="flex">
                  <div className="mt-1">
                    <DollarSvg />
                  </div>
                  <p className="px-5 font-semibold text-lg font-family">VIP</p>
                </div>

                <div className="flex">
                  <div className="mt-1">
                    <DoNotSvg />
                  </div>
                  <p className="px-5 font-semibold text-lg font-family">
                    Do Not Disturb
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
