import React, { useEffect, useState } from "react";
import TotalSalesChart from "./TotalSalesChart";
import TotalUsersChart from "./TotalUsersChart";
import TotalLocationsChart from "./TotalLocationsChart";
import TotalPointsChart from "./TotalPointsChart";

export default function DashboardCharts() {
  // const [name, setterFunction] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);
  // const functionName = () => {
  //   setterFunction(!name);
  // };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <TotalSalesChart />
      <TotalUsersChart />
      <TotalLocationsChart />
      <TotalPointsChart />
    </div>
  );
}
