import resturantimage from "../../Assets/Images/Rectangle.png";

const Location = [
  {
    image: { resturantimage },
    name: " Resturant-one",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been",
    id: 1234,
    city: "Dubai",
  },
  {
    image: { resturantimage },
    name: " Resturant-two",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been",
    id: 1235,
    city: "Dubai",
  },
  {
    image: { resturantimage },
    name: " Resturant-three",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been",
    id: 1236,
    city: "Dubai",
  },
];

const Tabledata = [
  {
    tableno: "1",
    status: "Occupied",
    person: "4",
    query: "",
  },

  {
    tableno: "2",
    status: "Disabled",
    person: "2",
    query: "I Need Water !",
  },
  {
    tableno: "3",
    status: "Available",
    person: "4",
    query: "",
  },
  {
    tableno: "4",
    status: "Occupied",
    person: "2",
    query: "please come !",
  },
  {
    tableno: "5",
    status: "Available",
    person: "2",
  },
  {
    tableno: "6",
    status: "Available",
    person: "4",
  },
  {
    tableno: "7",
    status: "Disabled",
    person: "2",
  },
  {
    tableno: "8",
    status: "Occupied",
    person: "2",
    query: "Need Ashtray",
  },
  {
    tableno: "9",
    status: "Available",
    person: "2",
  },
  {
    tableno: "10",
    status: "Available",
    person: "2",
  },

  {
    tableno: "11",
    status: "Disabled",
    person: "2",
  },
  {
    tableno: "12",
    status: "Occupied",
    person: "2",
    query: "Need Ashtray",
  },
  {
    tableno: "13",
    status: "Available",
    person: "2",
  },
  {
    tableno: "14",
    status: "Available",
    person: "2",
  },
  {
    tableno: "15",
    status: "Available",
    person: "2",
  },
  {
    tableno: "16",
    status: "Occupied",
    person: "4",
    query: "Need Ashtray",
  },

  {
    tableno: "17",
    status: "Available",
    person: "2",
  },
  {
    tableno: "18",
    status: "Occupied",
    person: "4",
    query: "Need Ashtray",
  },
  {
    tableno: "19",
    status: "Available",
    person: "2",
  },
  {
    tableno: "20",
    status: "Occupied",
    person: "2",
    query: "Need Ashtray",
  },
  {
    tableno: "21",
    status: "Available",
    person: "2",
  },
  {
    tableno: "22",
    status: "Disabled",
    person: "2",
  },
  {
    tableno: "23",
    status: "Occupied",
    person: "2",
    query: "Need Ashtray",
  },
  {
    tableno: "24",
    status: "Disabled",
    person: "2",
  },
  {
    tableno: "25",
    status: "Occupied",
    person: "2",
    query: "Need Plates",
  },

  {
    tableno: "26",
    status: "Disabled",
    person: "2",
  },
  {
    tableno: "27",
    status: "Available",
    person: "2",
  },
  {
    tableno: "28",
    status: "Occupied",
    person: "2",
    query: "Need wine",
  },
  {
    tableno: "29",
    status: "Disabled",
    person: "2",
  },
  {
    tableno: "30",
    status: "Occupied",
    person: "2",
  },
];

export default { Location, Tabledata };
