import React from 'react'

export default function WaitingSvg() {
  return (
<>
<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.29032 8.73907V1.45161C9.28994 1.06674 9.13688 0.697739 8.86473 0.425592C8.59258 0.153445 8.22358 0.000384298 7.83871 0H2.6129C2.22803 0.000384298 1.85903 0.153445 1.58688 0.425592C1.31474 0.697739 1.16167 1.06674 1.16129 1.45161V8.73907C0.833628 8.8063 0.539183 8.9845 0.327628 9.24358C0.116072 9.50267 0.000356154 9.8268 0 10.1613V17.7097C0 17.7867 0.0305875 17.8605 0.0850336 17.915C0.13948 17.9694 0.213324 18 0.290323 18H1.16129C1.23829 18 1.31213 17.9694 1.36658 17.915C1.42103 17.8605 1.45161 17.7867 1.45161 17.7097V12.4839H9V17.7097C9 17.7867 9.03059 17.8605 9.08503 17.915C9.13948 17.9694 9.21332 18 9.29032 18H10.1613C10.2383 18 10.3121 17.9694 10.3666 17.915C10.421 17.8605 10.4516 17.7867 10.4516 17.7097V10.1613C10.4513 9.8268 10.3355 9.50267 10.124 9.24358C9.91243 8.9845 9.61799 8.8063 9.29032 8.73907ZM1.74194 1.45161C1.74224 1.22071 1.8341 0.999357 1.99738 0.836085C2.16065 0.672813 2.382 0.580952 2.6129 0.580645H7.83871C8.06961 0.580952 8.29097 0.672813 8.45424 0.836085C8.61751 0.999357 8.70937 1.22071 8.70968 1.45161V3.48387H1.74194V1.45161ZM1.74194 4.06452H8.70968V8.70968H1.74194V4.06452ZM0.870968 17.4194H0.580645V12.4839H0.870968V17.4194ZM9.87097 17.4194H9.58065V12.4839H9.87097V17.4194ZM9.87097 11.9032H0.580645V10.1613C0.580952 9.93039 0.672814 9.70903 0.836085 9.54576C0.999357 9.38249 1.22071 9.29063 1.45161 9.29032H9C9.2309 9.29063 9.45226 9.38249 9.61553 9.54576C9.7788 9.70903 9.87066 9.93039 9.87097 10.1613V11.9032Z" fill="white"/>
<path d="M2.32255 3.19356H3.77416C3.85116 3.19356 3.92501 3.16297 3.97945 3.10853C4.0339 3.05408 4.06448 2.98024 4.06448 2.90324C4.06448 2.82624 4.0339 2.75239 3.97945 2.69795C3.92501 2.6435 3.85116 2.61292 3.77416 2.61292H2.32255C2.24555 2.61292 2.17171 2.6435 2.11726 2.69795C2.06281 2.75239 2.03223 2.82624 2.03223 2.90324C2.03223 2.98024 2.06281 3.05408 2.11726 3.10853C2.17171 3.16297 2.24555 3.19356 2.32255 3.19356ZM4.64513 3.19356H4.89916C4.97616 3.19356 5.05001 3.16297 5.10445 3.10853C5.1589 3.05408 5.18948 2.98024 5.18948 2.90324C5.18948 2.82624 5.1589 2.75239 5.10445 2.69795C5.05001 2.6435 4.97616 2.61292 4.89916 2.61292H4.64513C4.56813 2.61292 4.49429 2.6435 4.43984 2.69795C4.38539 2.75239 4.35481 2.82624 4.35481 2.90324C4.35481 2.98024 4.38539 3.05408 4.43984 3.10853C4.49429 3.16297 4.56813 3.19356 4.64513 3.19356Z" fill="white"/>
</svg>

</>

)
}
