import React, { useEffect, useState } from "react";

export default function ALReview() {
  // const [name, setterFunction] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);

  return (
    <>
      <p> ALReview</p>
    </>
  );
}
