export default function ImagePlus() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70867 18.1109V11.2909H0.888672V7.70867H7.70867V0.888672H11.2909V7.70867H18.1109V11.2909H11.2909V18.1109H7.70867Z"
        fill="url(#paint0_linear_650_3037)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_650_3037"
          x1="17.792"
          y1="0.888671"
          x2="-2.33925"
          y2="5.1639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#00A7F7" />
          <stop offset="1" stop-color="#00FC92" />
        </linearGradient>
      </defs>
    </svg>
  );
}
