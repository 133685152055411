import React from 'react'

export default function Configurations() {
  return (
  <>
   <div className='bg-bgBlack border border-solid border-white border-opacity-20 rounded-3xl px-2 py-6 '>
                                <h3 className='text-gradient-custom  text-xl font-bold pl-2 custom-font'>Optional Configurations</h3>

                                <form className="max-w-sm ">
                                    <div className='pt-5 mb-3  mx-2 font-semibold bg-transparent border-b-[1px] border-solid border-white border-opacity-20 '>
                                        <label for="first_name" className=" mb-2 text-lg font-semibold text-white px-3 py-8 ">Minimum Spent</label>
                                        <input type="text" id="table-no" className=' bg-transparent p-4 text-gray-300 w-[100%]  ' placeholder="$50" required />
                                    </div>


                                    <div className='pt-5 mb-3  mx-2 font-semibold bg-transparent border-b-[1px] border-solid border-white border-opacity-20 '>
                                        <label for="first_name" className=" mb-2 text-lg font-semibold text-white px-3 my-8">Maximum Stay</label>
                                        <input type="text" id="table-no" className=' bg-transparent p-4 text-gray-300 w-[100%] ' placeholder="50 Minutes" required />
                                    </div>

                                </form>




                            </div>

  
  </>
  )
}
