import React from 'react'

export default function YellowDotSvg() {
    return (
        <>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9.85938" cy="9.0498" r="9.0498" fill="#FAFF00" />
            </svg>

        </>
        )
}
