import React from 'react'

export default function CrossSvg() {
    return (
        <>
            <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.05" cx="20.8203" cy="20" r="20" fill="#00FC92" />
                <path opacity="0.8" d="M21.7365 20.0002L25.6303 23.8942C25.8837 24.1475 25.8837 24.557 25.6303 24.8103C25.377 25.0636 24.9675 25.0636 24.7142 24.8103L20.8203 20.9163L16.9265 24.8103C16.6731 25.0636 16.2636 25.0636 16.0104 24.8103C15.757 24.557 15.757 24.1475 16.0104 23.8942L19.9042 20.0002L16.0104 16.1062C15.757 15.8529 15.757 15.4434 16.0104 15.1901C16.1366 15.0637 16.3026 15.0002 16.4684 15.0002C16.6343 15.0002 16.8001 15.0637 16.9265 15.1901L20.8203 19.0841L24.7142 15.1901C24.8405 15.0637 25.0064 15.0002 25.1722 15.0002C25.3381 15.0002 25.5039 15.0637 25.6303 15.1901C25.8837 15.4434 25.8837 15.8529 25.6303 16.1062L21.7365 20.0002Z" fill="#00FC92" />
            </svg>

        </>
    )
}
