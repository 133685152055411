import React from "react";

export default function LikeSvg() {
  return (
    <>
      <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.00389 11.5658C6.8188 11.5658 6.6404 11.4988 6.50134 11.377C5.97616 10.9177 5.46985 10.4862 5.02312 10.1055L5.02084 10.1036C3.71114 8.98748 2.58017 8.02363 1.79326 7.07414C0.913634 6.01274 0.503906 5.00631 0.503906 3.90687C0.503906 2.83869 0.870191 1.85321 1.5352 1.13186C2.20815 0.401984 3.13153 0 4.13555 0C4.88596 0 5.57318 0.23725 6.17812 0.705096C6.48339 0.941229 6.76009 1.23025 7.00389 1.56736C7.24777 1.23025 7.5244 0.941229 7.82977 0.705096C8.4347 0.23725 9.12192 0 9.87234 0C10.8763 0 11.7997 0.401984 12.4727 1.13186C13.1377 1.85321 13.5039 2.83869 13.5039 3.90687C13.5039 5.00629 13.0943 6.01272 12.2146 7.07404C11.4277 8.02363 10.2968 8.98737 8.98733 10.1034C8.53982 10.4846 8.03272 10.9169 7.50635 11.3772C7.36724 11.4988 7.1887 11.5659 7.00389 11.5658ZM4.13555 0.761514C3.34676 0.761514 2.62214 1.07633 2.09498 1.648C1.56 2.22833 1.26532 3.03052 1.26532 3.90687C1.26532 4.83155 1.60898 5.65852 2.37953 6.58827C3.12429 7.48694 4.23205 8.43097 5.51469 9.52406L5.51705 9.52604C5.96544 9.90816 6.47376 10.3414 7.0028 10.804C7.53501 10.3405 8.04412 9.90659 8.49341 9.52385C9.77593 8.43076 10.8836 7.48694 11.6284 6.58827C12.3988 5.65852 12.7425 4.83155 12.7425 3.90687C12.7425 3.03049 12.4478 2.2283 11.9128 1.648C11.3857 1.07633 10.661 0.761514 9.87234 0.761514C9.2945 0.761514 8.76397 0.945215 8.29554 1.30741C7.87809 1.63035 7.58729 2.03858 7.41677 2.32423C7.32912 2.47111 7.1748 2.55879 7.00389 2.55879C6.83299 2.55879 6.67867 2.47111 6.59099 2.32423C6.42062 2.03858 6.1298 1.63035 5.71222 1.30741C5.24382 0.945215 4.71328 0.761514 4.13555 0.761514Z"
          fill="#E27D00"
        />
      </svg>
    </>
  );
}
