import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateHeader } from "../store/slices/headerSlice";
import DashboardTable from "../Components/Tables/DashboardTable";
import DashboardCharts from "../Components/Charts/DashboardCharts";
import CreateNewReservation from "../Components/Modal/CreateNewReservation";
import Legends from "../Components/Modal/Legends";
import QuickRequest from "../Components/Modal/QuickRequest";
import OrderDetails from "../Components/Modal/OrderDetails";
import TablePercentage from "../Components/Modal/TablePercentage";
import CheckOut from "../Components/Modal/CheckOut";

export default function HomeDashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    const headerData = {
      title: "Main Dashboard",
      breadCrumbs: [
        {
          name: "Dashboard",
          link: "/dashboard",
        },
      ],
    };
    // console.log("headerData>>>", headerData);

    dispatch(updateHeader(headerData));
  });

  return (
    <div className="space-y-4 mt-8">
      <DashboardCharts />
      <DashboardTable />
    </div>
  );
}
