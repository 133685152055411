import React, { useEffect, useState } from "react";
import LocationsTableData from "../../utils/dummyData/LocationsTableData.json";
import GradientText from "../Common/GradientText";
import PencilIcon from "../Svgs/PencilIcon";

export default function LocationTable() {
  let data = LocationsTableData;
  let dataHeaders = [
    "No",
    "Location ID",
    "Location name",
    "Joined Date",
    "City",
    "Manager Name",
    "Email",
    "Mobile Number",
    "Fee",
    "Status",
    "Edit",
  ];

  return (
    <div className="my-8 space-y-4 w-full bg-bgBlack p-6 rounded-2xl border border-opacity-20 border-white ">
      <div className="flex justify-between">
        <div className="text-3xl font-bold">
          <GradientText text="Location List" />
        </div>
        <span className="mr-2"> Dropdown</span>
      </div>

      <hr className="w-full h-0.5 bg-gradient-to-r from-transparent via-white to-transparent  border-none" />

      <div className="overflow-hidden rounded-lg shadow">
        <table className="w-full divide-y divide-tableBorderColor">
          <thead className="">
            <tr>
              {Array.isArray(dataHeaders) &&
                dataHeaders.length > 0 &&
                dataHeaders.map((item, index) => {
                  return (
                    <th
                      key={index}
                      className="p-2 text-sm font-semibold tracking-wide text-left"
                    >
                      {item}
                    </th>
                  );
                })}

              {/* <th>No</th>
              <th>City</th>
              <th>Id Customer</th>
              <th>First Name</th>
              <th>Lasr Name</th>
              <th>Date</th>
              <th>Location Name</th>
              <th>Splitting Option</th>
              <th>Account</th>
              <th>Amount</th> */}
            </tr>
          </thead>
          <tbody className="space-y-2">
            {Array.isArray(data) &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <tr key={index} className="border-b border-tableBorderColor">
                    <td className="p-2 py-4 text-sm  whitespace-nowrap">No.</td>
                    <td className="p-2 py-4 text-sm  whitespace-nowrap">
                      {item?.locationID}
                    </td>
                    <td className="p-2 py-4 text-sm  whitespace-nowrap">
                      {item?.locationName}
                    </td>
                    <td className="p-2 py-4 text-sm  whitespace-nowrap">
                      {item?.joinedDate}
                    </td>
                    <td className="p-2 py-4 text-sm  whitespace-nowrap">
                      {item?.city}
                    </td>
                    <td className="p-2 py-4 text-sm  whitespace-nowrap">
                      {item?.mnagerName}
                    </td>
                    <td className="p-2 py-4 text-sm  whitespace-nowrap">
                      {item?.email}
                    </td>
                    <td className="p-2 py-4 text-sm  whitespace-nowrap">
                      {item?.mobileNumber}
                    </td>

                    <td className="p-2 py-4 text-sm whitespace-nowrap">
                      {item?.fee}
                    </td>
                    <td className={`p-2 text-sm whitespace-nowrap `}>
                      <div
                        className={`${
                          item?.status === "Active"
                            ? "bg-bgNavItem text-lightGreenBlur"
                            : "bg-redBorder "
                        } py-1 px-3 rounded-lg max-w-fit`}
                      >
                        {item?.status}
                      </div>
                    </td>

                    <td className={`p-2 py-4 text-sm whitespace-nowrap `}>
                      <div
                        className={`flex w-9 h-9 items rounded-full bg-lightGreenBlur bg-opacity-10 items-center justify-center`}
                      >
                        <PencilIcon />
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
