export default function ImageThumbnail() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 4C16.0955 4 12.4963 4.32201 9.60741 4.70355C7.00745 5.04693 5.04693 7.00745 4.70355 9.60741C4.32201 12.4963 4 16.0955 4 20C4 23.9045 4.32201 27.5037 4.70355 30.3926C5.04693 32.9926 7.00745 34.9531 9.60741 35.2965C12.4963 35.678 16.0955 36 20 36C23.9045 36 27.5037 35.678 30.3926 35.2965C32.9926 34.9531 34.9531 32.9926 35.2965 30.3926C35.678 27.5037 36 23.9045 36 20C36 16.0955 35.678 12.4963 35.2965 9.60741C34.9531 7.00745 32.9926 5.04693 30.3926 4.70355C27.5037 4.32201 23.9045 4 20 4ZM9.08368 0.737985C4.69009 1.31825 1.31825 4.69009 0.737985 9.08368C0.339068 12.1042 0 15.8825 0 20C0 24.1175 0.339068 27.8958 0.737985 30.9163C1.31825 35.3099 4.69009 38.6818 9.08368 39.262C12.1042 39.6609 15.8825 40 20 40C24.1175 40 27.8958 39.6609 30.9163 39.262C35.3099 38.6818 38.6818 35.3099 39.262 30.9163C39.6609 27.8958 40 24.1175 40 20C40 15.8825 39.6609 12.1042 39.262 9.08368C38.6818 4.69009 35.3099 1.31825 30.9163 0.737985C27.8958 0.339068 24.1175 0 20 0C15.8825 0 12.1042 0.339068 9.08368 0.737985Z"
          fill="white"
        />
        <path
          d="M14.0012 18C16.2104 18 18.0012 16.2091 18.0012 14C18.0012 11.7909 16.2104 10 14.0012 10C11.7921 10 10.0012 11.7909 10.0012 14C10.0012 16.2091 11.7921 18 14.0012 18Z"
          fill="white"
        />
        <path
          d="M32.2439 21.4146L35.8325 25.0032C35.7087 26.8756 35.5279 28.613 35.3285 30.1561L29.4154 24.243C28.6344 23.462 27.368 23.462 26.587 24.243L22.2439 28.5862C19.9007 30.9293 16.1017 30.9293 13.7586 28.5862L13.4154 28.243C12.6344 27.462 11.3681 27.462 10.587 28.243L5.76887 33.0611C5.21072 32.3044 4.83727 31.3961 4.70473 30.3926C4.63246 29.8453 4.56231 29.2726 4.49609 28.6771L7.75858 25.4146C10.1017 23.0714 13.9007 23.0714 16.2439 25.4146L16.587 25.7577C17.368 26.5388 18.6344 26.5388 19.4154 25.7577L23.7586 21.4146C26.1017 19.0714 29.9007 19.0714 32.2439 21.4146Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
