export default function EyeClosedSvg({ togglePasswordVisibility }) {
  return (
    
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={togglePasswordVisibility}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.2185 1.58568C21.542 1.32692 21.5944 0.854953 21.3357 0.531506C21.0769 0.20806 20.605 0.155619 20.2815 0.414376L16.9562 3.07464C15.279 1.56835 13.1397 0.250028 10.75 0.250028C8.60907 0.250028 6.67327 1.30902 5.10175 2.59574C3.52166 3.88949 2.22964 5.47868 1.36144 6.68804L1.28984 6.78762C0.767181 7.51385 0.33695 8.11166 0.336944 9C0.336937 9.88833 0.76709 10.4861 1.28964 11.2122L1.36121 11.3117C1.90751 12.0727 2.61909 12.9805 3.46226 13.8698L0.281506 16.4144C-0.0419401 16.6731 -0.0943812 17.1451 0.164376 17.4685C0.423133 17.792 0.895102 17.8444 1.21855 17.5857L21.2185 1.58568ZM10.75 1.75003C12.5277 1.75003 14.2524 2.70932 15.7673 4.02574L13.1506 6.11909C12.5006 5.57707 11.6634 5.25003 10.75 5.25003C8.67896 5.25003 7.00003 6.92896 7.00003 9.00003C7.00003 9.61541 7.14871 10.1971 7.41192 10.71L4.63803 12.9292C3.81702 12.0746 3.11802 11.1868 2.57974 10.4369C1.95595 9.56801 1.83694 9.35854 1.83694 9.00001C1.83695 8.64149 1.956 8.43195 2.57995 7.56281C3.40922 6.40768 4.61351 4.93417 6.05203 3.75634C7.49913 2.57149 9.10426 1.75003 10.75 1.75003ZM10.75 6.75003C11.1862 6.75003 11.5933 6.87383 11.9384 7.0889L8.62436 9.7401C8.54377 9.50853 8.50003 9.25964 8.50003 9.00003C8.50003 7.75739 9.50739 6.75003 10.75 6.75003Z"
        fill="white"
      />
      <path
        d="M19.1454 5.39079C18.8832 5.07006 18.4108 5.02255 18.09 5.28467C17.7693 5.54679 17.7218 6.01928 17.9839 6.34001C18.3344 6.76888 18.6481 7.18396 18.92 7.56273C19.5441 8.43194 19.6631 8.6415 19.6631 9.00002C19.6631 9.35855 19.5441 9.56809 18.9201 10.4372C18.0908 11.5924 16.8865 13.0659 15.448 14.2437C14.0009 15.4286 12.3958 16.25 10.75 16.25C9.34522 16.25 7.96821 15.6521 6.68756 14.7322C6.35113 14.4906 5.88252 14.5674 5.64088 14.9038C5.39923 15.2403 5.47607 15.7089 5.8125 15.9505C7.23703 16.9737 8.91761 17.75 10.75 17.75C12.891 17.75 14.8268 16.691 16.3983 15.4043C17.9784 14.1106 19.2704 12.5214 20.1386 11.312L20.2102 11.2124C20.7329 10.4862 21.1631 9.88837 21.1631 9.00002C21.1631 8.11167 20.7329 7.51382 20.2102 6.78754L20.1386 6.68796C19.8509 6.28725 19.5182 5.84696 19.1454 5.39079Z"
        fill="white"
      />
      <path
        d="M14.5 9.00003C14.5 8.58581 14.1642 8.25003 13.75 8.25003C13.3358 8.25003 13 8.58581 13 9.00003C13 10.2427 11.9927 11.25 10.75 11.25C10.3358 11.25 10 11.5858 10 12C10 12.4142 10.3358 12.75 10.75 12.75C12.8211 12.75 14.5 11.0711 14.5 9.00003Z"
        fill="white"
      />
    </svg>
  );
}
