import React from "react";
import { useState } from "react";
import TableDetails from "../../../Components/Modal/CreateNewTable/TableDetails";
import Configurations from "../../../Components/Modal/CreateNewTable/Configurations";
import TableFeatures from "../../../Components/Modal/CreateNewTable/TableFeatures";
import SeatCapacity from "../../../Components/Modal/CreateNewTable/SeatCapacity";


export default function CreatNewTable({showAddTable,setShowAddTable}) {

  const toggleVisibility = () => {
    setShowAddTable(!showAddTable);
  };


  return (
    <>
     {showAddTable && (
    <div className="z-[2] w-screen h-screen fixed top-0 left-0 bg-[#000000C7] p-3 border border-solid border-white border-opacity-20 rounded-3xl">
    <div className="w-[70%] mt-10 mx-auto bg-bgBlack p-3 border border-solid border-white border-opacity-20 rounded-3xl ">
        <div className=" w-[75%] h-[90%] m-auto mt-5">
          <h2 className="text-gradient-custom custom-font mb-10">
            Create new table
          </h2>
          {/* middle Div */}
          <div className="md:flex justify-between ">
            <div className="lg:flex flex-col space-y-5 sm:w-[100%] md:w-[40%] lg:w-[50%] xl:w-[38%] mr-5 ">
              <TableDetails />
              <Configurations />
            </div>

           {/* table features */}
            <div className="sm:w-[100%] md:w-[40%] lg:w-[50%] xl:w-[38%] mr-5 mt-5">
              <TableFeatures />
            </div>
            {/* Seat Capacity */}
            <div className="sm:w-[100%] md:w-[40%] lg:w-[50%] xl:w-[38%] mt-5">
              <SeatCapacity />
            </div>
       
          
          </div>
          {/* button Div */}
          <div className="flex justify-end">
          <button onClick={toggleVisibility} className=" w-[177px] h-[70px] mt-5 bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur  text-black rounded-[30px]" >Create New Table</button>
          </div>
        </div>
      </div>
    </div>
          )}
    </>
  );
}
