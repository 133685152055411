import React, { useState, useEffect } from "react";
import LikeSvg from "../../Svgs/LikeSvg";
import UserSvg from "../../Svgs/UserSvg";
import Items from "../../../utils/dummyData/Dummydata";
import DollarSvg from "../../Svgs/DollarSvg";
import ButtonTableDetails from "../Location/ButtonTableDetails";
import BookBtn from "../Location/BookBtn";
import CustomerQuery from "./CustomerQuery";
import EditSvg from "../../Svgs/EditSvg";
import OrderDetails from "../../Modal/OrderDetails";
import TablePercentage from "../../Modal/TablePercentage";
import CheckOut from "../../Modal/CheckOut";
import { useNavigate } from "react-router-dom";

const TableDescription = ({ showEditIcon, toggleEditTable }) => {
  const { Tabledata } = Items;
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showTablePercentage, setShowTablePercentage] = useState(false);
  const [showCheckOut, setShowCheckOut] = useState(false);

  function getStatusColor(status) {
    switch (status) {
      case "Disabled":
        return "bg-redborder";
      case "Occupied":
        return "bg-yellowborder";
      case "Available":
        return "bg-greenborder";
      default:
        return "bg-gray-500";
    }
  }

  function getTextColor(status) {
    switch (status) {
      case "Disabled":
        return "text-redborder";
      case "Occupied":
        return "text-yellowborder";
      case "Available":
        return "text-greenborder";
      default:
        return "text-gray-500";
    }
  }

  function getIcon(status) {
    switch (status) {
      case "Disabled":
        return null;
      case "Occupied":
        return <DollarSvg clickFunction={handleCheckOut} />;
      case "Available":
        return <LikeSvg />;
      default:
        return null;
    }
  }

  function getBtn(status) {
    switch (status) {
      case "Disabled":
        return <div className="mt-9"></div>;
      case "Occupied":
        return <ButtonTableDetails />;
      case "Available":
        return <BookBtn />;
      default:
        return null;
    }
  }

  function handleCheckOut() {
    setShowCheckOut(true);
    setShowOrderDetails(false);
    setShowTablePercentage(false);
  }
  function handleOrderDetailsClick() {
    setShowOrderDetails(true);
    setShowCheckOut(false);
    setShowTablePercentage(false);
  }
  function handleTablePercentage() {
    setShowTablePercentage(true);
    setShowOrderDetails(false);
    setShowCheckOut(false);
  }

  return (
    <div className="flex justify-between w-[99%] flex-wrap">
      {Tabledata.map((data, index) => (
        <div
          key={index}
          className="flex flex-col justify-center items-center mb-8 ml-3 relative"
        >
          {data.query &&
            data.query.trim().length > 0 &&
            data.status === "Occupied" && <CustomerQuery query={data.query} />}

          <div className="w-[230px] h-[73px] border border-solid overflow-hidden border-white border-opacity-20 rounded-2xl flex">
            {/* color div */}
            <div
              className={`w-[7%] cursor-pointer ${getStatusColor(data.status)}`}
              onClick={handleOrderDetailsClick}
            ></div>
            {/* items div */}
            <div className="w-[81%] flex">
              {/* icon div */}
              <div className="w-[30%] p-2 grid grid-cols-1 gap-y-10">
                <div></div>
                <div className="flex justify-around">
                  <UserSvg />
                  <h3 className="-mt-[6px]">{data.person}</h3>
                </div>
              </div>
              {/* heading div */}
              <div className="w-[70%] flex flex-col">
                <h2
                  onClick={handleTablePercentage}
                  className="font-bold text-2xl ml-5 text-gradient-custom mt-2 cursor-pointer"
                >
                  T {data.tableno}
                </h2>
                <h3
                  className={`w-[7%] font-bold text-xs ml-5 ${getTextColor(
                    data.status
                  )}`}
                >
                  {data.status}
                </h3>
              </div>
            </div>
            {/* icons div */}
            <div className="w-[12%] h-[73px] grid grid-cols-1 pt-2 gap-y-6">
              {getIcon(data.status)}
              {showEditIcon && <EditSvg clickFunction={toggleEditTable} />}
            </div>
          </div>
          {getBtn(data.status)}
        </div>
      ))}

      {showOrderDetails && (
        <OrderDetails
          showOrderDetails={showOrderDetails}
          setShowOrderDetails={setShowOrderDetails}
        />
      )}
      {showTablePercentage && (
        <TablePercentage
          showTablePercentage={showTablePercentage}
          setShowTablePercentage={setShowTablePercentage}
        />
      )}
      {showCheckOut && (
        <CheckOut
          showCheckOut={showCheckOut}
          setShowCheckOut={setShowCheckOut}
        />
      )}
    </div>
  );
};

export default TableDescription;
