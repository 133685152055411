import React, { useState } from "react";
import logo from "../../Assets/Images/logo.png";
import NavDashboard from "../Svgs/NavDashboard";
import NavLocation from "../Svgs/NavLocation";
import NavFinanace from "../Svgs/NavFinanace";
import NavOrders from "../Svgs/NavOrders";
import NavSystem from "../Svgs/NavSystem";
import NavSettings from "../Svgs/NavSettings";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { useLocation, Link } from "react-router-dom";

// Sample data for sidebar items

const Sidebar = () => {
  const [openSubMenuId, setOpenSubMenuId] = useState(null);
  const pathname = useLocation().pathname;
  const selectedMenu = pathname.split("/")[1] || "dashboard";
  const activeColor = "#00FC92";
  // console.log("pathname>>>", pathname);

  const sidebarItems = [
    {
      id: 1,
      title: "Dashboard",
      iconLeft: (
        <NavDashboard
          isActive={selectedMenu === "dashboard" ? true : false}
          activeColor={activeColor}
        />
      ),
      link: "/dashboard",
      subItems: [],
    },
    {
      id: 2,
      title: "Locations Management",
      iconLeft: (
        <NavLocation
          isActive={selectedMenu === "manage" ? true : false}
          activeColor={activeColor}
        />
      ),
      link: "/manage",
      subItems: [
        { id: 21, title: "Location management", link: "manage/locations" },
        { id: 22, title: "Location Profile", link: "manage/profile" },
        { id: 23, title: "Manage Staff", link: "manage/staff" },
        { id: 24, title: "Manage Menu", link: "manage/menu" },
        { id: 25, title: "Voucher codes", link: "manage/codes" },
        { id: 26, title: "Manage Tables", link: "manage/tables" },
        {
          id: 27,
          title: "Manage Reservations",
          link: "manage/reservations",
        },
        { id: 28, title: "Send Notifications", link: "manage/notification" },
      ],
    },
    {
      id: 3,
      title: "Finance",
      iconLeft: (
        <NavFinanace
          isActive={selectedMenu === "finance" ? true : false}
          activeColor={activeColor}
        />
      ),
      link: "/finance",
      subItems: [
        { id: 31, title: "Reports", link: "finance/reports" },
        { id: 32, title: "Orders", link: "finance/orders" },
        { id: 33, title: "Bills", link: "finance/bills" },
        { id: 34, title: "Shareabill Invoices", link: "finance/invoices" },
      ],
    },
    {
      id: 4,
      title: "Orders Stream",
      iconLeft: (
        <NavOrders
          isActive={selectedMenu === "orders" ? true : false}
          activeColor={activeColor}
        />
      ),
      link: "/orders",
      subItems: [],
    },
    {
      id: 5,
      title: "System Notifications",
      iconLeft: (
        <NavSystem
          isActive={selectedMenu === "notifications" ? true : false}
          activeColor={activeColor}
        />
      ),
      link: "/notifications",
      subItems: [],
    },
    {
      id: 6,
      title: "Settings",
      iconLeft: (
        <NavSettings
          isActive={selectedMenu === "settings" ? true : false}
          activeColor={activeColor}
        />
      ),
      link: "/settings",
      subItems: [],
    },
    // {
    //   id: 3,
    //   title: "Orders",
    //   iconLeft: "orders-icon",
    //   link: "/orders",
    //   subItems: [],
    // },
    // Add more sidebar items as needed
  ];

  const handleItemClick = (itemId) => {
    setOpenSubMenuId((prevId) => (prevId === itemId ? null : itemId));
  };

  const renderSubItems = (subItems) => {
    return subItems.map((item) => (
      <div key={item.id} className="ml-14 mt-3 ">
        <Link
          to={item.link}
          className={`${
            pathname.toLowerCase().includes(item.link.toLowerCase())
              ? "bg-white bg-opacity-10"
              : ""
          } text-white py-1 px-2 rounded-lg border-none hover:text-white min-w-full`}
          onClick={() => setOpenSubMenuId(null)}
        >
          {item.title}
        </Link>
      </div>
    ));
  };

  const renderSidebarItems = () => {
    return sidebarItems.map((item) => (
      <div key={item.id} className="relative">
        {item.subItems.length === 0 ? (
          <>
            <Link
              to={item.link}
              className={`${
                selectedMenu === item.link.slice(1)
                  ? "bg-lightGreenBlur bg-opacity-10 text-lightGreenBlur"
                  : "bg-bgNavItem"
              } py-2 px-4 rounded-xl border-none font-semibold flex items-center gap-3 cursor-pointer`}
              onClick={() => handleItemClick(item.id)}
            >
              {item.iconLeft}
              <div className="truncate">{item.title}</div>
            </Link>
            {selectedMenu === item.link.slice(1) && (
              <div className="absolute -right-3 top-0 bg-lightGreenBlur w-1 h-full rounded-2xl"></div>
            )}
          </>
        ) : (
          <>
            <div
              className={`${
                selectedMenu === item.link.slice(1)
                  ? "bg-lightGreenBlur bg-opacity-10 text-lightGreenBlur"
                  : "bg-bgNavItem"
              } py-2 px-4 rounded-xl border-none font-semibold flex items-center gap-3 cursor-pointer`}
              onClick={() => handleItemClick(item.id)}
            >
              {item.iconLeft}
              <div className="truncate">{item.title}</div>

              {item.subItems.length > 0 && (
                <span className="ml-auto">
                  {openSubMenuId === item.id ? (
                    <IoMdArrowDropdown />
                  ) : (
                    <IoMdArrowDropright />
                  )}
                </span>
              )}
            </div>
            {selectedMenu === item.link.slice(1) && (
              <div className="absolute -right-3 top-0 bg-lightGreenBlur w-1 h-full rounded-2xl"></div>
            )}
          </>
        )}

        {openSubMenuId === item.id && item.subItems.length > 0 && (
          <div className="mt-2 ease-in-out duration-300">
            {renderSubItems(item.subItems)}
          </div>
        )}
      </div>
    ));
  };

  return (
    <div className="bg-bgBlack min-h-[1050px] border border-solid border-white border-opacity-20 rounded-3xl">
      <div className="flex justify-center my-8">
        <img src={logo} alt="logo" />
      </div>
      <hr className="w-full" />

      <div className="py-6 px-3 flex gap-3 flex-col relative">
        {renderSidebarItems()}
      </div>
    </div>
  );
};

export default Sidebar;
