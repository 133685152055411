import React, { useState, useEffect } from "react";
import SearchIcon from "../../../Components/Svgs/SearchIcon";
import ResturantLocation from "../../../Components/TableManagement/Location/ResturantLocation";
import FloorManagement from "../FloorManagem/FloorManagement";
import CreateNewTable from "../CreateNewTable/CreatNewTable";
import EditTable from "../EditTable/EditTable";
import { useDispatch } from "react-redux";
import { updateHeader } from "../../../store/slices/headerSlice";

export default function LocationList() {
  const dispatch = useDispatch();

  useEffect(() => {
    const headerData = {
      title: "Table Management",
      breadCrumbs: [
        {
          name: "manage",
          link: "/manage/locations",
        },
        {
          name: "add",
          link: "/manage/locations/add",
        },
      ],
    };

    dispatch(updateHeader(headerData));
  });
  const [showFloorManagement, setShowFloorManagement] = useState(false);
  // const [showEditTable, setShowEditTable] = useState(false);

  function handleFloorManagementClick() {
    setShowFloorManagement(true);
    // setShowEditTable(false);
  }

  // function handleEditTable() {
  //   setShowEditTable(true);
  //   setShowFloorManagement(false);
  // }

  return (
    <>
      {!showFloorManagement ? (
        <div className="">
          {/* Main Div */}
          <div className="">
            {/* section one */}
            <div className="w-[100%] h-[185px] bg-bgBlack  border border-solid border-white border-opacity-20 rounded-3xl flex flex-wrap justify-around items-center">
              {/* Search Div */}
              <div className="w-[25%] p-3 grid grid-cols-1 gap-2  ">
                <h3 className="text-gradient-custom custom-font">Search</h3>
                <div className="w-[80%] h-[46px] border border-solid border-white border-opacity-20 rounded-3xl flex justify-start items-center">
                  <div className="p-3">
                    <SearchIcon />
                  </div>
                  <div className="p-2">
                    <input
                      type="text"
                      placeholder="Search"
                      className="bg-transparent text-white font-bold border-none outline-none placeholder-opacity-0"
                    />
                  </div>
                </div>
              </div>
              {/* Filter Div */}
              <div className="w-[25%] p-3 grid grid-cols-1 gap-2">
                <div className="">
                  <h3 className="text-gradient-custom custom-font">Filters</h3>
                </div>
                <div className="flex justify-between border-b border-solid border-white border-opacity-20 pb-3">
                  <select
                    placeholder="Select Country"
                    className="bg-transparent font-bold border-none outline-none w-[100%]"
                  >
                    <option selected className="bg-bgBlack text-lg pt-2">
                      Select city
                    </option>
                    <option value="f1" className="bg-bgBlack">
                      Dubai
                    </option>
                    <option value="f2" className="bg-bgBlack">
                      Ajman
                    </option>
                    <option value="f3" className="bg-bgBlack">
                      Sharjah
                    </option>
                    <option value="ter" className="bg-bgBlack">
                      Abu Dhabi
                    </option>
                  </select>
                </div>
              </div>
              {/* City Div */}
              <div className="w-[25%] p-3 pt-14 grid grid-cols-1 gap-2">
                <div className="flex justify-between border-b border-solid border-white border-opacity-20 pb-3">
                  <select
                    placeholder="Select Country"
                    className="bg-transparent font-bold border-none outline-none w-[100%]"
                  >
                    <option
                      selected
                      className="bg-bgBlack text-lg pt-2 rounded-full"
                    >
                      Select city
                    </option>
                    <option value="f1" className="bg-bgBlack">
                      Dubai
                    </option>
                    <option value="f2" className="bg-bgBlack">
                      Ajman
                    </option>
                    <option value="f3" className="bg-bgBlack">
                      Sharjah
                    </option>
                    <option value="ter" className="bg-bgBlack">
                      Abu Dhabi
                    </option>
                  </select>
                </div>
              </div>
              <div className="w-[10.5%] p-3"></div>
              {/* button div */}
              <div className="w-[14.5%] p-3 flex items-center ">
                <button className="bg-lightGreenBlur mt-5 text-black font-bold w-[177px] h-[50px] rounded-3xl">
                  Search
                </button>
              </div>
            </div>
            {/* section two */}
            <ResturantLocation
              onFloorManagementClick={handleFloorManagementClick}
            />
          </div>
        </div>
      ) : null}

      {showFloorManagement && (
        <FloorManagement
        // onFloorManagementClickEdit={handleEditTable}
        />
      )}

      {/* {showEditTable && (
        <EditTable
          showEditIcon={true}
          onFloorManagementClickEdit={handleEditTable}
         
        />
      )} */}
    </>
  );
}
