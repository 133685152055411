export default function NavFinanace({ isActive, activeColor }) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.87896 3.62392C5.87896 3.62392 5.29686 3.08703 4.39959 3.3573C3.5757 3.60549 3.37113 4.73277 3.93097 5.17187C4.25246 5.42406 4.73616 5.63134 5.38808 5.86178C6.84713 6.37753 6.30774 8.43518 4.8236 8.44506C4.24443 8.44893 3.97364 8.41215 3.46484 8.07931"
        stroke={isActive ? activeColor : "white"}
        strokewidthidth="0.5"
        strokemiterlimititerlimit="10"
        strokelinecapinecap="round"
        strokelinejoininejoin="round"
      />
      <path
        d="M4.82422 2.58773V9.19727"
        stroke={isActive ? activeColor : "white"}
        strokewidthidth="0.5"
        strokemiterlimititerlimit="10"
        strokelinecapinecap="round"
        strokelinejoininejoin="round"
      />
      <path
        d="M9.20117 11.0227V18.1254C9.20117 18.5653 9.55781 18.9219 9.99768 18.9219H18.6751C19.115 18.9219 19.4716 18.5653 19.4716 18.1254V8.43191C19.4716 7.99204 19.115 7.63544 18.6751 7.63544H9.99768C9.55781 7.63544 9.20117 7.99204 9.20117 8.43191V9.5161M17.795 11.0946C17.795 11.1272 17.7886 11.1594 17.7762 11.1895C17.7637 11.2195 17.7454 11.2469 17.7224 11.2699C17.6994 11.2929 17.6721 11.3111 17.642 11.3236C17.6119 11.336 17.5797 11.3425 17.5472 11.3424H11.1256C11.0598 11.3424 10.9968 11.3163 10.9503 11.2699C10.9038 11.2234 10.8777 11.1603 10.8777 11.0946V9.44529C10.8777 9.37956 10.9038 9.31652 10.9503 9.27004C10.9968 9.22356 11.0598 9.19745 11.1256 9.19745H17.5472C17.5797 9.19744 17.6119 9.20385 17.642 9.2163C17.6721 9.22875 17.6994 9.24701 17.7224 9.27002C17.7454 9.29304 17.7637 9.32036 17.7762 9.35044C17.7886 9.38051 17.795 9.41274 17.795 9.44529V11.0946Z"
        stroke={isActive ? activeColor : "white"}
        strokewidthidth="0.5"
        strokemiterlimititerlimit="10"
        strokelinecapinecap="round"
        strokelinejoininejoin="round"
      />
      <path
        d="M17.5464 9.1968H11.1248C11.0591 9.1968 10.9961 9.22291 10.9496 9.26939C10.9031 9.31586 10.877 9.3789 10.877 9.44464V11.094C10.877 11.1265 10.8834 11.1587 10.8958 11.1888C10.9083 11.2189 10.9265 11.2462 10.9496 11.2692C10.9726 11.2922 10.9999 11.3105 11.03 11.3229C11.0601 11.3354 11.0923 11.3418 11.1248 11.3418H17.5464C17.579 11.3418 17.6112 11.3354 17.6413 11.3229C17.6714 11.3105 17.6987 11.2922 17.7217 11.2692C17.7447 11.2462 17.763 11.2189 17.7754 11.1888C17.7879 11.1587 17.7943 11.1265 17.7943 11.094V9.44464C17.7943 9.41209 17.7879 9.37986 17.7754 9.34979C17.763 9.31973 17.7447 9.2924 17.7217 9.26939C17.6987 9.24637 17.6713 9.22812 17.6413 9.21566C17.6112 9.20321 17.579 9.1968 17.5464 9.1968Z"
        stroke={isActive ? activeColor : "white"}
        strokewidthidth="0.5"
        strokemiterlimititerlimit="10"
        strokelinecapinecap="round"
        strokelinejoininejoin="round"
      />
      <path
        d="M17.09 6.12262V4.58416C17.09 4.31325 16.9824 4.05343 16.7908 3.86186L13.5504 0.621501C13.4556 0.526628 13.343 0.451371 13.219 0.400028C13.0951 0.348684 12.9622 0.322261 12.8281 0.322267H1.55275C0.988567 0.322267 0.53125 0.779626 0.53125 1.34381V20.6562C0.53125 21.2204 0.988567 21.6777 1.55275 21.6777H16.0685C16.6327 21.6777 17.09 21.2204 17.09 20.6562V20.4258"
        stroke={isActive ? activeColor : "white"}
        strokewidthidth="0.5"
        strokemiterlimititerlimit="10"
        strokelinecapinecap="round"
        strokelinejoininejoin="round"
      />
      <path
        d="M16.9958 4.16016H14.0637C13.6143 4.16016 13.25 3.79587 13.25 3.3465V0.413153"
        stroke={isActive ? activeColor : "white"}
        strokewidthidth="0.5"
        strokemiterlimititerlimit="10"
        strokelinecapinecap="round"
        strokelinejoininejoin="round"
      />
    </svg>
  );
}
