import React from 'react'

export default function RedDotSvg() {
    return (
        <>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9.85938" cy="9.24902" r="9.0498" fill="#FF0000" />
            </svg>


        </>

    )
}
