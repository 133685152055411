import ReservationCheckIn from "../../Components/TableReservation/ReservationCheckIn";
import CreateNewReservation from "../../Components/Modal/CreateNewReservation";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { updateHeader } from "../../store/slices/headerSlice";
import GradientButton from "../../Components/Buttons/GradientButton";

export default function Reservation() {
  const [newReservation, setNewReservation] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const headerData = {
      title: "Reservations",
      breadCrumbs: [
        {
          name: "manage",
          link: "/manage/reservations",
        },
        {
          name: "add",
          link: "/manage/reservations/add",
        },
      ],
    };

    dispatch(updateHeader(headerData));
  }, [dispatch]);

  function handleNewReservation() {
    setNewReservation(true);
  }

  return (
    <>
      <div className="w-[100%]  h-[185px] bg-bgBlack p-3 border border-solid border-white border-opacity-20 rounded-3xl ">
        <div className="flex justify-between mx-5">
          <div className="m-5">
            <h3 className="text-xl font-semibold text-gradient-custom  font-family w-[200px] mb-3 ">
              Average time spent
            </h3>
            <div className="flex items-baseline space-x-3">
              <div className="flex flex-col space-y-2 ml-2">
                <div className="w-12 h-12 bg-[#78788052] border-2 border-[#00FC92] border-opacity-20 rounded-md flex justify-center items-center">
                  <p className="text-lg text-gray-200">00</p>
                </div>
                <p className="ml-2 text-sm">Hour</p>
              </div>

              <p className="text-xl font-bold">:</p>

              <div className="flex flex-col space-y-2">
                <div className="w-12 h-12 bg-[#78788052] border-2 border-[#00FC92] border-opacity-20 rounded-md flex justify-center items-center">
                  <p className="text-lg text-gray-200">00</p>
                </div>
                <p className="ml-3 text-sm">Min</p>
              </div>
            </div>
          </div>

          <div className="flex sm:w-[50%] md:w-[90%]  xl:w-[100%] justify-end space-x-6 items-center">
            <div className="  w-fit cursor-pointer text-center text-white font-semibold  border border-solid border-white border-opacity-20 rounded-2xl">
              <p className="py-2 px-5 h-auto">View Tables</p>
            </div>

            <div onClick={handleNewReservation} className="flex justify-center">
              <GradientButton
                buttonText="Create new Reservation "
                width=""
                backgroundColor="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur"
                border="rounded-2xl"
              />
            </div>
          </div>
        </div>
      </div>
      {newReservation && (
        <CreateNewReservation
          newReservation={newReservation}
          setNewReservation={setNewReservation}
        />
      )}
      <div>
        <ReservationCheckIn />
      </div>
    </>
  );
}
