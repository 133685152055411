export default function NavDashboard({ isActive, activeColor }) {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00254 10.4987H7.50254V10.9987V15.9987C7.50254 16.2726 7.2764 16.4988 7.00254 16.4988H4.00254C3.72868 16.4988 3.50254 16.2726 3.50254 15.9987V8.99875V8.49875H3.00254H1.30885L9.66594 0.971371C9.66613 0.971193 9.66633 0.971016 9.66653 0.970839C9.85653 0.801387 10.1486 0.801387 10.3385 0.970839C10.3387 0.971016 10.3389 0.971193 10.3391 0.971371L18.6962 8.49875H17.0025H16.5025V8.99875V15.9987C16.5025 16.2726 16.2764 16.4988 16.0025 16.4988H13.0025C12.7287 16.4988 12.5025 16.2726 12.5025 15.9987V10.9987V10.4987H12.0025H8.00254Z"
        stroke={isActive ? activeColor : "white"}
      />
    </svg>
  );
}
