import React, { useEffect, useState } from "react";
import GradientText from "../../Common/GradientText";
import AddInputField from "../../Common/AddInputField";
import ImagePicker from "../../Common/ImagePicker";

export default function ALS2() {
  const [logoImage, setLogoImage] = useState(false);
  const [coverImage, setCoverImage] = useState(false);
  const [thumbImage, setThumbImage] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);

  return (
    <div className="flex gap-3 items-start">
      <div className="space-y-3 py-5 px-8 bg-bgBlack rounded-3xl w-1/4 h-auto max-h-fit">
        <div className="font-semibold text-2xl">
          <GradientText text="Manager details" />
        </div>
        <div className="space-y-5">
          <AddInputField
            label="Name"
            name="managerName"
            placeholder="type here"
          />
          <AddInputField
            label="Email"
            name="managerEmail"
            placeholder="type here"
          />
          <AddInputField
            label="Mobile Number"
            name="managerMobileNumber"
            placeholder="type here"
          />
        </div>
      </div>
      <div className="space-y-3 py-5 px-8 bg-bgBlack rounded-3xl w-1/4">
        <div className="font-semibold text-2xl space-y-4">
          <GradientText text="Branding" />
          <div className="space-y-4">
            <ImagePicker
              imageId="logoImage"
              imageState={logoImage}
              imageSetterFunction={setLogoImage}
            />
            <ImagePicker
              imageId="coverImage"
              imageState={coverImage}
              imageSetterFunction={setCoverImage}
            />
            <ImagePicker
              imageId="thumbImage"
              imageState={thumbImage}
              imageSetterFunction={setThumbImage}
            />
          </div>
        </div>
        <div className="space-y-5"></div>
      </div>{" "}
      <div className="space-y-5 w-1/4">
        <div className="space-y-3 py-5 px-8 bg-bgBlack rounded-3xl w-full">
          <div className="font-semibold text-2xl">
            <GradientText text="Primary Color" />
          </div>
          <div className="space-y-5"></div>
        </div>{" "}
        <div className="space-y-3 py-5 px-8 bg-bgBlack rounded-3xl w-full">
          <div className="font-semibold text-2xl">
            <GradientText text="Secondary Color" />
          </div>
          <div className="space-y-5"></div>
        </div>{" "}
      </div>
      <div className="space-y-3 py-5 px-8 bg-bgBlack rounded-3xl w-1/4 h-auto max-h-fit">
        <div className="font-semibold text-2xl">
          <GradientText text="Social Media Llinks" />
        </div>
        <div className="space-y-5">
          <AddInputField
            label="Instagram"
            name="instagram"
            placeholder="link here"
          />
          <AddInputField
            label="Twitter"
            name="twitter"
            placeholder="link here"
          />
          <AddInputField
            label="Facebook"
            name="facebook"
            placeholder="link here"
          />
          <AddInputField
            label="Snapchat"
            name="snapchat"
            placeholder="link here"
          />
          <AddInputField label="Google" name="google" placeholder="link here" />
        </div>
      </div>
    </div>
  );
}
