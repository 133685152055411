import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "./SearchBar";
import GradientText from "../Common/GradientText";
import { IoIosArrowBack } from "react-icons/io";

export default function Header() {
  const title = useSelector((state) => state.headerReducer.title);
  const breadCrumbs = useSelector((state) => state.headerReducer.breadCrumbs);
  const navigate = useNavigate();
  // const [name, setterFunction] = useState(false);

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex justify-between mb-10">
      <div>
        <div className=" flex items-center">
          <div>
            <IoIosArrowBack
              onClick={() => navigate(-1)}
              className="cursor-pointer"
            />
          </div>
          <div>
            {Array.isArray(breadCrumbs) &&
              breadCrumbs.length > 0 &&
              breadCrumbs.map((item, index) => {
                return (
                  <Link key={index} to={item.link} className="">
                    <span>{item.name}</span>
                    {index < breadCrumbs.length - 1 && <span> / </span>}
                  </Link>
                );
              })}
          </div>
        </div>
        <div className="text-4xl font-bold  ">
          <GradientText text={title} />
        </div>
      </div>
      <SearchBar />
    </div>
  );
}
