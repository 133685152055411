import React from "react";

export default function DollarSvg({ clickFunction }) {
  return (
    <>
      <svg
        onClick={clickFunction}
        width="12"
        height="19"
        viewBox="0 0 12 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="cursor-pointer" 
      >
        <path
          d="M6.74032 8.26461V4.06737C7.63813 4.21897 8.48539 4.58734 9.2086 5.14053C9.35265 5.2394 9.52279 5.29334 9.69749 5.29552C10.2591 5.29552 10.717 4.8436 10.7229 4.28199C10.7241 4.04111 10.6298 3.80957 10.4606 3.63811C9.4145 2.74234 8.10337 2.21454 6.7284 2.13569V0.728638C6.7284 0.326782 6.40288 0.00126162 6.00103 0.00126162C5.99269 8.09968e-05 5.98431 8.09968e-05 5.97597 8.09968e-05C5.56818 -0.00589592 5.23192 0.319662 5.22595 0.728638V2.08798C2.54305 2.27876 0.718684 3.93621 0.718684 6.14214C0.718684 8.84887 3.02002 9.60012 5.22595 10.1963V14.9659C4.06214 14.8097 2.96516 14.3268 2.06607 13.5708C1.89676 13.4361 1.68687 13.361 1.46986 13.3562C0.920165 13.3944 0.496874 13.8546 0.503995 14.4055C0.502802 14.6464 0.59713 14.8779 0.766315 15.0494C1.9977 16.1584 3.58157 16.7961 5.23782 16.8499V18.1616C5.23782 18.1699 5.23901 18.1783 5.23901 18.1866C5.25808 18.5944 5.60507 18.9092 6.01287 18.889C6.41472 18.889 6.74025 18.5634 6.74025 18.1616V16.8261C9.99549 16.6115 11.3071 14.6321 11.3071 12.5335C11.3072 9.71936 8.94628 8.86083 6.74032 8.26461ZM5.2379 7.88305C3.93818 7.50148 2.92465 7.108 2.92465 5.99906C2.92465 4.89012 3.8428 4.09121 5.2379 3.98392V7.88305ZM6.74032 14.9897V10.6494C8.08775 11.031 9.13703 11.5437 9.12511 12.7957C9.12511 13.702 8.50506 14.7751 6.74032 14.9897Z"
          fill="#FAFF00"
        />
      </svg>
    </>
  );
}
