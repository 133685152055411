import React from "react";

export default function UserSvg() {
  return (
    <>
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.41455 0C4.82335 0 3.53027 1.29309 3.53027 2.88427C3.53027 4.47547 4.82335 5.76855 6.41455 5.76855C8.00553 5.76855 9.29882 4.47942 9.29882 2.88427C9.29882 2.17894 9.04589 1.53016 8.62572 1.02839L8.62515 1.02774C8.09537 0.40012 7.30376 0 6.41455 0ZM3.95757 2.88427C3.95757 1.52908 5.05937 0.4273 6.41455 0.4273C7.17215 0.4273 7.84607 0.76742 8.29837 1.30307C8.65616 1.73049 8.87152 2.28278 8.87152 2.88427C8.87152 4.24305 7.76994 5.34125 6.41455 5.34125C5.05937 5.34125 3.95757 4.23945 3.95757 2.88427Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.81251 6.58789C2.43295 6.58789 0.503906 8.51694 0.503906 10.8965L0.503906 11.7867C0.503906 11.9047 0.599561 12.0004 0.717556 12.0004L12.1122 12.0004C12.2302 12.0004 12.3259 11.9047 12.3259 11.7867V10.8965C12.3259 8.51694 10.3968 6.58789 8.01726 6.58789H4.81251ZM0.931206 10.8965C0.931206 8.75291 2.66893 7.01519 4.81251 7.01519H8.01726C10.1608 7.01519 11.8986 8.75291 11.8986 10.8965V11.5731L0.931206 11.5731L0.931206 10.8965Z"
          fill="white"
        />
      </svg>
    </>
  );
}
