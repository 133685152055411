import React, { useEffect, useState } from "react";
import dashboardTableData from "../../utils/dummyData/DashboardTableData.json";

export default function DashboardTable() {
  let data = dashboardTableData;
  let dataHeaders = [
    "No",
    "City",
    "Id Customer",
    "First Name",
    "Last Name",
    "Date",
    "Location Name",
    "Splitting Option",
    "Account",
    "Amount",
  ];

  return (
    <div className="my-8 space-y-4 w-full bg-bgBlack p-6 rounded-2xl border border-opacity-20 border-white ">
      <div className="flex justify-between">
        <h1 className="text-4xl ">Recent Transactions</h1>
        <span className="mr-2"> Dropdown</span>
      </div>

      <hr className="w-full h-0.5 bg-gradient-to-r from-transparent via-white to-transparent  border-none" />

      <div className="overflow-hidden rounded-lg shadow">
        <table className="w-full divide-y divide-tableBorderColor">
          <thead className="">
            <tr>
              {Array.isArray(dataHeaders) &&
                dataHeaders.length > 0 &&
                dataHeaders.map((item, index) => {
                  return (
                    <th
                      key={index}
                      className="p-2 text-sm font-semibold tracking-wide text-left"
                    >
                      {item}
                    </th>
                  );
                })}

              {/* <th>No</th>
              <th>City</th>
              <th>Id Customer</th>
              <th>First Name</th>
              <th>Lasr Name</th>
              <th>Date</th>
              <th>Location Name</th>
              <th>Splitting Option</th>
              <th>Account</th>
              <th>Amount</th> */}
            </tr>
          </thead>
          <tbody className="">
            {Array.isArray(data) &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <tr key={index} className="border-b border-tableBorderColor">
                    <td className="p-2 text-sm  whitespace-nowrap">
                      {item?.id}
                    </td>
                    <td className="p-2 text-sm  whitespace-nowrap">
                      {item?.city}
                    </td>
                    <td className="p-2 text-sm  whitespace-nowrap">
                      #{item?.customerId}
                    </td>
                    <td className="p-2 text-sm  whitespace-nowrap">
                      {item?.firstName}
                    </td>
                    <td className="p-2 text-sm  whitespace-nowrap">
                      {item?.lastName}
                    </td>
                    <td className="p-2 text-sm  whitespace-nowrap">
                      {item?.date}
                    </td>
                    <td className="p-2 text-sm  whitespace-nowrap">
                      {item?.locationName}
                    </td>
                    <td className="p-2 text-sm  whitespace-nowrap">
                      {item?.splittingOption}
                    </td>
                    <td
                      className="p-2 text-sm whitespace-nowrap"
                      dangerouslySetInnerHTML={{ __html: item?.account }}
                    ></td>
                    <td className="p-2 text-sm whitespace-nowrap">
                      {item?.amount}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
