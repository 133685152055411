import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateHeader } from "../../store/slices/headerSlice";

export default function Nothing() {
  const dispatch = useDispatch();

  useEffect(() => {
    let dataHeaders = {
      title: "404 Page",
    };
    dispatch(updateHeader(dataHeaders));
  });
  return (
    <>
      <h1> 404! no page found </h1>
    </>
  );
}
