import React, { useState } from "react";
import CrossSvg from "../Svgs/CrossSvg";
import GradientButton from "../Buttons/GradientButton";

const OrderDetails = ({ showOrderDetails, setShowOrderDetails }) => {
  const toggleVisibility = () => {
    setShowOrderDetails(!showOrderDetails);
  };

  const menuItems = [
    { name: "Burger", price: 50 },
    { name: "Barger", price: 50 },
    // Add more menu items as needed
  ];
  const [orderItems, setOrderItems] = useState([]);
  const [username, setUsername] = useState("");
  const [total, setTotal] = useState(0);

  const addItemToOrder = (item, price) => {
    const existingIndex = orderItems.findIndex(
      (orderItem) => orderItem.item === item
    );

    if (existingIndex !== -1) {
      const updatedOrderItems = [...orderItems];
      updatedOrderItems[existingIndex].quantity++;
      setOrderItems(updatedOrderItems);
    } else {
      const newOrderItems = [...orderItems, { item, quantity: 1, price }];
      setOrderItems(newOrderItems);
    }
    setTotal(total + price);
  };

  return (
    <>
      {showOrderDetails && (
        <div className="z-[2] w-screen h-screen fixed top-0 left-0  bg-[#000000C7] p-3 border border-solid border-white border-opacity-20 rounded-3xl ">
          <div>
            <div className="bg-bgBlack w-[22%] h-[90%] border border-solid border-white border-opacity-20 rounded-3xl absolute left-[65%] top-16">
              <div className="flex justify-between m-3 py-2 border-b-[1px] border-solid border-white border-opacity-20">
                <div className="flex justify-between w-[100%]">
                  <h2 className=" font-normal text-3xl font-family mt-2">
                    Order Details
                  </h2>
                  <div
                    onClick={toggleVisibility}
                    className="cursor-pointer flex justify-end mt-5 mr-2"
                  >
                    <CrossSvg />
                  </div>
                </div>
              </div>

              <div className="flex justify-between mx-3 py-3 border-b-[1px] border-solid border-white border-opacity-20">
                <div className="flex">
                  <h2 className=" font-light text-xl mr-2">Table Numbers</h2>
                  <h3 className="text-2xl font-bold text-gradient-custom  font-family ">
                    T 26
                  </h3>
                </div>

                <div className="flex">
                  <h2 className=" font-light text-xl mr-2">Order Id</h2>
                  <h3 className="text-2xl font-bold text-gradient-custom  font-family ">
                    #7598
                  </h3>
                </div>
              </div>

              {/* ------------------------------------------------------------------------- */}

              <div className="flex justify-between m-2 py-2 bg-[#78788052] rounded-2xl">
                <div className="flex px-3">
                  <h2 className=" font-light text-lg mr-2">Username</h2>
                  <h3 className="text-lg font-bold text-gradient-custom  font-family ">
                    Mark Andreson
                  </h3>
                </div>

                <div className="flex px-4">
                  <h2 className=" font-light text-lg mr-2">Time</h2>
                  <h3 className="text-lg font-bold text-gradient-custom  font-family ">
                    4:50 PM
                  </h3>
                </div>
              </div>

              <div>
                <div className=" m-3 border-b-[1px] border-solid border-white border-opacity-20">
                  <div className="m-3 pb-3 flex justify-between">
                    <p>Item Name</p>

                    <p>Quantity</p>

                    <p>Price</p>
                  </div>

                  <div>
                    <div className="m-3 flex justify-between ">
                      <div className="text-lg font-bold text-gradient-custom  font-family pb-1">
                        Burger{" "}
                      </div>

                      <div className="text-lg font-bold text-gradient-custom  font-family pb-1 ml-5">
                        {" "}
                        {2}{" "}
                      </div>

                      <div className="text-lg font-bold text-gradient-custom  font-family">
                        ${100}
                      </div>
                    </div>
                    <div className="m-3 flex justify-between ">
                      <div className="text-lg font-bold text-gradient-custom  font-family pb-1">
                        Burger{" "}
                      </div>

                      <div className="text-lg font-bold text-gradient-custom  font-family pb-1 ml-5">
                        {" "}
                        {2}{" "}
                      </div>

                      <div className="text-lg font-bold text-gradient-custom  font-family">
                        ${100}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="m-3 pb-1  border-b-[1px] border-solid border-white border-opacity-20">
                  <p className="font-light text-lg  text-end">
                    Total{" "}
                    <span className="pl-4 pr-2 text-lg font-bold text-gradient-custom  font-family">
                      {" "}
                      ${200}
                    </span>
                  </p>
                </div>
              </div>

              {/* // ---------------------------------------------------------------- */}

              <div className="flex justify-between m-2 py-2 bg-[#78788052] rounded-2xl">
                <div className="flex px-3">
                  <h2 className=" font-light text-lg mr-2">Username</h2>
                  <h3 className="text-lg font-bold text-gradient-custom  font-family ">
                    Mark Andreson
                  </h3>
                </div>

                <div className="flex px-4">
                  <h2 className=" font-light text-lg mr-2">Time</h2>
                  <h3 className="text-lg font-bold text-gradient-custom  font-family ">
                    4:50 PM
                  </h3>
                </div>
              </div>

              <div>
                <div className=" m-3  border-b-[1px] border-solid border-white border-opacity-20">
                  <div className="m-3 pb-3 flex justify-between">
                    <p>Item Name</p>

                    <p>Quantity</p>

                    <p>Price</p>
                  </div>

                  <div>
                    <div className="m-3 flex justify-between ">
                      <div className="text-lg font-bold text-gradient-custom  font-family pb-1">
                        Burger{" "}
                      </div>

                      <div className="text-lg font-bold text-gradient-custom  font-family pb-1 ml-5">
                        {" "}
                        {2}{" "}
                      </div>

                      <div className="text-lg font-bold text-gradient-custom  font-family">
                        ${100}
                      </div>
                    </div>
                    <div className="m-3 flex justify-between ">
                      <div className="text-lg font-bold text-gradient-custom  font-family pb-1">
                        Burger{" "}
                      </div>

                      <div className="text-lg font-bold text-gradient-custom  font-family pb-1 ml-5">
                        {" "}
                        {2}{" "}
                      </div>

                      <div className="text-lg font-bold text-gradient-custom  font-family">
                        ${100}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mx-3 pb-1  border-b-[1px] border-solid border-white border-opacity-20">
                  <p className="font-light text-lg  text-end">
                    Total{" "}
                    <span className="pl-4 pr-2 text-lg font-bold text-gradient-custom  font-family">
                      {" "}
                      ${200}
                    </span>
                  </p>
                </div>

                <div className="mx-3 py-1  border-b-[1px] border-solid border-white border-opacity-20 flex flex-col items-end">
                  <div className="w-[40%] flex justify-between">
                    <p className="font-light text-xl">Sub Total</p>
                    <p className=" text-xl font-bold text-gradient-custom  font-family">
                      {" "}
                      ${400}
                    </p>
                  </div>

                  <div className="w-[30%] flex justify-between">
                    <p className="font-light text-xl">Tax</p>
                    <p className=" text-xl font-bold text-gradient-custom  font-family">
                      {" "}
                      ${10}
                    </p>
                  </div>
                </div>

                <div className="mx-3 py-1  border-b-[1px] border-solid border-white border-opacity-20 flex flex-col items-end">
                  <div className="w-[30%] flex justify-between">
                    <p className="font-light text-xl"> Total</p>
                    <p className=" text-xl font-bold text-gradient-custom  font-family">
                      {" "}
                      ${410}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex justify-center my-2">
                <GradientButton
                  buttonText="Edit "
                  width="w-[95%]"
                  backgroundColor="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur"
                  border="rounded-2xl"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderDetails;
