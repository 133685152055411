import React from "react";
import DeleteSvg from "../Svgs/DeleteSvg";
import EditBigSvg from "../Svgs/EditBigSvg";
import QrCodeSvg from "../Svgs/QrCodeSvg";
import DownloadSvg from "../Svgs/DownloadSvg";
import GradientButton from "../Buttons/GradientButton";

export default function CheckOut({ showCheckOut, setShowCheckOut }) {
  const toggleVisibility = () => {
    setShowCheckOut(!showCheckOut);
  };

  return (
    <>
      {showCheckOut && (
        <div className="w-screen h-screen fixed top-0 left-0  bg-[#000000C7] p-3 border border-solid border-white border-opacity-20 rounded-3xl ">
          <div className="bg-bgBlack w-[917px] h-[540px] border border-solid border-white border-opacity-20 rounded-3xl absolute left-[30%] top-40">
            <div className="flex justify-between m-5 py-5 border-b-[1px] border-solid border-white border-opacity-20">
              <div className="flex">
                <h2 className=" font-normal text-2xl font-family mr-5">
                  Table Number
                </h2>
                <h3 className="text-2xl font-bold text-gradient-custom  font-family ">
                  T 22
                </h3>
              </div>

              <div className="flex">
                <div className="flex">
                  <DeleteSvg />
                  <p className="text-xl p-2 mx-2">Delete</p>
                </div>
                <div className="flex">
                  <EditBigSvg />
                  <p className="text-xl p-2 ml-2">Edit Table</p>
                </div>
              </div>
            </div>

            <div className="flex justify-between mx-5 border-b-[1px] border-solid border-white border-opacity-20 pb-3">
              <div className="flex flex-col w-[75%]">
                <div className="flex pb-4 border-b-[1px] border-solid border-white border-opacity-20 w-[45%]">
                  <h2 className=" font-normal text-xl font-family mr-5">
                    Seat Capacity
                  </h2>
                  <h3 className="text-xl font-bold text-gradient-custom  font-family ">
                    4 Person
                  </h3>
                </div>

                <div className="flex py-4 border-b-[1px] border-solid border-white border-opacity-20 w-[45%]">
                  <h2 className=" font-normal text-xl font-family mr-5">
                    Minimum Spent
                  </h2>
                  <h3 className="text-xl font-bold text-gradient-custom  font-family ">
                    $100
                  </h3>
                </div>

                <div className="flex py-4 border-b-[1px] border-solid border-white border-opacity-20 w-[45%]">
                  <h2 className=" font-normal text-xl font-family mr-5">
                    Maximum Stay
                  </h2>
                  <h3 className="text-xl font-bold text-gradient-custom  font-family ">
                    1 Hr
                  </h3>
                </div>

                <div className="flex py-6">
                  <div className="w-[20%]">
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle1"
                        className="toggle-checkbox absolute block w-7 h-7 rounded-full bg-white border-4 appearance-none cursor-pointer"
                      />
                      <label
                        for="toggle1"
                        className="toggle-label block overflow-hidden h-7 rounded-full bg-[#78788052] cursor-pointer"
                      ></label>
                    </div>
                    <label
                      for="toggle1"
                      className="font-normal text-lg font-family px-2 py-1"
                    >
                      Vip
                    </label>
                  </div>

                  <div className="w-[35%]">
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle2"
                        className="toggle-checkbox absolute block w-7 h-7 rounded-full bg-white border-4 appearance-none cursor-pointer"
                      />
                      <label
                        for="toggle2"
                        className="toggle-label block overflow-hidden h-7 rounded-full bg-[#78788052] cursor-pointer"
                      ></label>
                    </div>
                    <label
                      for="toggle2"
                      className="font-normal text-lg font-family px-2 py-1"
                    >
                      Special Occasion
                    </label>
                  </div>

                  <div className="w-[30%]">
                    <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input
                        type="checkbox"
                        name="toggle"
                        id="toggle3"
                        className="toggle-checkbox absolute block w-7 h-7 rounded-full bg-white border-4 appearance-none cursor-pointer"
                      />
                      <label
                        for="toggle3"
                        className="toggle-label block overflow-hidden h-7 rounded-full bg-[#78788052] cursor-pointer"
                      ></label>
                    </div>
                    <label
                      for="toggle3"
                      className="font-normal text-lg font-family px-2 py-1"
                    >
                      Do Not Disturb
                    </label>
                  </div>
                </div>

                <div className=" flex justify-center py-3 w-32 cursor-pointer text-[#FAFF00] font-semibold  border border-solid border-white border-opacity-20 rounded-2xl">
                  <div className="w-2 h-2 bg-[#FAFF00] rounded-full mt-2 mr-2"></div>
                  <p>Occupied</p>
                </div>
              </div>

              <div>
                <div className="">
                  <div className="relative inline-block w-12 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input
                      type="checkbox"
                      name="toggle"
                      id="toggle4"
                      className="toggle-checkbox absolute block w-7 h-7 rounded-full bg-white border-4 appearance-none cursor-pointer"
                    />
                    <label
                      for="toggle4"
                      className="toggle-label block overflow-hidden h-7 rounded-full bg-[#78788052] cursor-pointer"
                    ></label>
                  </div>
                  <label
                    for="toggle4"
                    className="font-normal text-lg font-family px-2 py-1"
                  >
                    Disable Table
                  </label>
                </div>

                <div className="w-[175px] h-[230px] bg-[#78788052] rounded-2xl mt-10">
                  <p className="font-normal text-[16px] font-family text-center pt-2">
                    Table QR Code
                  </p>
                  <div className="flex justify-center py-3">
                    <QrCodeSvg />
                  </div>

                  <div className="flex justify-evenly mx-auto py-3 w-36 bg-gradient-to-br cursor-pointer from-lightGreenBlur to-lightBlueBlur text-black font-semibold  border border-solid border-white border-opacity-20 rounded-2xl">
                    <p>Download</p>
                    <DownloadSvg />
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={toggleVisibility}
              className="flex justify-evenly py-6 px-5"
            >
              <GradientButton
                buttonText="Reset "
                width="w-[15%]"
                backgroundColor="bg-[#FF0000]"
                border="rounded-2xl"
              />

              <GradientButton
                buttonText="Transfer guest "
                width="w-[18%]"
                backgroundColor="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur"
                border="rounded-2xl"
              />

              <GradientButton
                buttonText="Quick discount "
                width="w-[20%]"
                backgroundColor="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur"
                border="rounded-2xl"
              />

              <GradientButton
                buttonText="Accept cash"
                width="w-[15%]"
                backgroundColor="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur"
                border="rounded-2xl"
              />

              <GradientButton
                buttonText="View Current Order"
                width="w-[25%]"
                backgroundColor="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur"
                border="rounded-2xl"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
