import { combineReducers } from "@reduxjs/toolkit";
import counterSlice from "./counterSlice";
import headerSlice from "./headerSlice";

const rootReducer = combineReducers({
  counter: counterSlice,
  headerReducer: headerSlice,
});

export default rootReducer;
