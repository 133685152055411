import React from "react";
import DollarSvg from "../../Svgs/DollarSvg";
import LikeSvg from "../../Svgs/LikeSvg";
import DoNotSvg from "../../Svgs/DoNotSvg";
import IndoorSvg from "../../Svgs/IndoorSvg";
import OutdoorSvg from "../../Svgs/OutdoorSvg";

export default function TableFeatures() {
  return (
    <>
      <div className="bg-bgBlack border border-solid border-white border-opacity-20 rounded-3xl px-2 py-6  w-[100%] h-fit">
        <h3 className="text-gradient-custom  text-xl font-bold pl-2  custom-font">
          Table Features
        </h3>

        <ul className="  text-sm font-medium bg-transparent p-4">
          <li className=" mb-2 bg-transparent border border-solid w-fit px-2 border-gray-200 border-opacity-20 rounded-3xl pr-4">
            <div className="flex items-center justify-between px-3 ">
            <input
    id=""
    type="checkbox"
    value=""
    class="w-4 h-4 border-2 rounded-full border-green-500 text-green-500"
/>


              <div className="mx-3">
                <DollarSvg />
              </div>
              <label for="" className=" py-3 text-sm font-medium ">
                Vip
              </label>
            </div>
          </li>

          <li className=" mb-2 bg-transparent border border-solid w-fit px-2 border-gray-200 border-opacity-20 rounded-3xl pr-4">
            <div className="flex items-center justify-between px-3 ">
            <input
    id=""
    type="checkbox"
    value=""
    class="w-4 h-4 border-2 rounded-full border-green-500 text-green-500"
/>

              <div className="mx-3">
                <DoNotSvg />{" "}
              </div>
              <label for="" className=" py-3 text-sm font-medium ">
                Do not Disturb
              </label>
            </div>
          </li>

          <li className=" mb-2 bg-transparent border border-solid w-fit px-2 border-gray-200 border-opacity-20 rounded-3xl pr-4">
            <div className="flex items-center justify-between px-3 ">
              <input
                id=""
                type="checkbox"
                value=""
                className="w-4 h-4 border-2 rounded-3xl "
              />
              <div className="mx-3">
                <LikeSvg />
              </div>
              <label for="" className=" py-3 text-sm font-medium ">
                Special Occussion
              </label>
            </div>
          </li>

          <li className=" mb-2 bg-transparent border border-solid w-fit px-2 border-gray-200 border-opacity-20 rounded-3xl pr-4">
            <div className="flex items-center justify-between px-3 ">
              <input
                id=""
                type="checkbox"
                value=""
                className="w-4 h-4 border-2 rounded-3xl "
              />
              <div className="mx-3">
                <IndoorSvg />
              </div>
              <label for="" className=" py-3 text-sm font-medium ">
                Indoor
              </label>
            </div>
          </li>

          <li className=" mb-2 bg-transparent border border-solid w-fit px-2 border-gray-200 border-opacity-20 rounded-3xl pr-4">
            <div className="flex items-center justify-between px-3 ">
              <input
                id=""
                type="checkbox"
                value=""
                className="w-4 h-4 border-2 rounded-3xl "
              />
              <div className="mx-3">
                <OutdoorSvg />
              </div>
              <label for="" className=" py-3 text-sm font-medium ">
                Outdoor
              </label>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}
