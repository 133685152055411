import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  title: "Main Dashboard",
  breadCrumbs: [
    {
      name: "Dashboard",
      link: "/dashboard",
    },
  ],
};

const headerSlice = createSlice({
  name: "Header",
  initialState,
  reducers: {
    updateHeader: (state, action) => {
      const updatedHeader = { ...action.payload };
      state.title = updatedHeader?.title;
      state.breadCrumbs = updatedHeader?.breadCrumbs;
    },
  },
});

export const { updateHeader, addByValu } = headerSlice.actions;

export default headerSlice.reducer;
