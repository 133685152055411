import React from "react";
import PercentageSvg from "../Svgs/PercentageSvg";
import CrossSvg from "../Svgs/CrossSvg";
import GradientButton from "../Buttons/GradientButton";
import { useState } from "react";

export default function TablePercentage({
  showTablePercentage,
  setShowTablePercentage,
}) {
  const toggleVisibility = () => {
    setShowTablePercentage(!showTablePercentage);
  };

  return (
    <>
      {showTablePercentage && (
        <div className="w-screen h-screen fixed top-0 left-0  bg-[#000000C7] p-3 border border-solid border-white border-opacity-20 rounded-3xl ">
          <div className="bg-bgBlack  md:w-[30%]  xl:w-[20%] border border-solid border-white border-opacity-20 rounded-3xl absolute right-10 top-40">
            <div
              onClick={toggleVisibility}
              className=" flex justify-end mt-5 mr-3 cursor-pointer"
            >
              <CrossSvg />
            </div>

            <div className="flex flex-col space-y-5 items-center">
              <PercentageSvg />
            </div>

            <div className="py-2 mx-10 my-1">
              <h2 className=" font-medium text-xl font-family pb-2">
                Table Number
              </h2>
              <h3 className="text-4xl font-bold text-gradient-custom  font-family ">
                T 01
              </h3>
            </div>

            <div className=" border-b-[1px] border-solid border-white border-opacity-20 mx-10">
              <label
                for="first_name"
                className=" font-medium text-xl font-family"
              >
                Add Percentage
              </label>
              <input
                type="text"
                id="table-no"
                className=" w-[100%] font-normal bg-transparent text-xl text-gray-200 py-2 mt-3"
                placeholder="00%"
                required
              />
            </div>

            <ul className=" w-fit text-sm font-medium bg-transparent mx-10 py-5">
              <li className="w-full mb-2 bg-transparent">
                <div className="flex items-center">
                  <input
                    id="green"
                    name="color"
                    color="green"
                    type="radio"
                    value=""
                    class="w-8 h-8 "
                  />
                  <label
                    for="list-radio-passport"
                    className="w-full py-1 ms-2 text-lg font-medium"
                  >
                    By Percentage
                  </label>
                </div>
              </li>

              <li className="w-full mb-2 bg-transparent">
                <div className="flex items-center">
                  <input
                    id="green"
                    name="color"
                    color="green"
                    type="radio"
                    value=""
                    class="w-8 h-8 "
                  />
                  <label
                    for="list-radio-passport"
                    className="w-full py-2 ms-2 text-lg font-medium"
                  >
                    By Fixed amount
                  </label>
                </div>
              </li>
            </ul>

            <div className="flex justify-center mb-7 ">
              <GradientButton
                buttonText="Next"
                width="w-[35%]"
                backgroundColor="bg-[#00FC92]"
                border="rounded-2xl"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
