import React, { useState } from "react";
import CrossSvg from "../Svgs/CrossSvg";
import GradientButton from "../Buttons/GradientButton";
import AshTraySvg from "../Svgs/AshTraySvg";
import WaiterSvg from "../Svgs/WaiterSvg";
import QuickRequestSvg from "../Svgs/QuickRequestSvg";

export default function QuickRequest({ showRequest, setShowRequest }) {
  const toggleVisibility = () => {
    setShowRequest(!showRequest);
  };

  return (
    <>
      {showRequest && (
        <div className="z-[2] w-screen h-screen fixed top-0 left-0 bg-[#000000C7] p-3 border border-solid border-white border-opacity-20 rounded-3xl ">
          <div className="bg-bgBlack w-[20%] border border-solid border-white border-opacity-20 rounded-3xl absolute right-10 top-20">
            <div
              className=" cursor-pointer flex justify-end mt-5 mr-2"
              onClick={toggleVisibility}
            >
              <CrossSvg />
            </div>

            <div className="flex flex-col space-y-10 items-center">
              <div>
                <QuickRequestSvg />
              </div>
              <h3 className="custom-font text-gradient-custom ">
                Quick Requests
              </h3>
            </div>

            <div className="py-6 border-b-[1px] border-solid border-white border-opacity-20 mx-10 my-3">
              <h2 className=" font-medium text-2xl font-family pb-5">
                Table Number
              </h2>
              <h3 className="text-4xl font-bold text-gradient-custom font-family ">
                T 01
              </h3>
            </div>

            <div className=" flex justify-between py-5 border-b-[1px] border-solid border-white border-opacity-20 mx-10 my-3">
              <div className="flex">
                <WaiterSvg />
                <h2 className=" font-medium text-2xl font-family ml-5 mt-3">
                  Call Waiter
                </h2>
              </div>

              <div className="w-[34.5px] h-[34.5px] rounded-full border border-[2pc border-[#00FC924D] bg-[#00FC921A]">
                <p className=" text-xl text-center mt-[2px]">1</p>
              </div>
            </div>

            <div className="flex justify-between py-4 border-b-[1px] border-solid border-white border-opacity-20 mx-10 my-3">
              <div className="flex pl-2">
                <AshTraySvg />
                <h2 className=" font-medium text-2xl font-family pb-5 ml-5">
                  Ashtray
                </h2>
              </div>

              <div className="w-[34.5px] h-[34.5px] rounded-full border border-[#00FC924D] bg-[#00FC921A]">
                <p className=" text-xl text-center mt-[2px]">1</p>
              </div>
            </div>

            <div className="flex justify-center my-10 ">
              <GradientButton
                buttonText="Resolve"
                width="w-[35%]"
                backgroundColor="bg-[#00FC92]"
                border="rounded-2xl"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
