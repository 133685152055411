import React from 'react'

export default function DownloadSvg() {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.60034 11.45C5.03456 10.8843 5.89403 10.0248 6.45982 10.5906L11.3964 15.5271V0.599979C11.3964 -0.200156 12.6119 -0.200156 12.6119 0.599979V15.5271L17.5484 10.5903C18.1142 10.0245 18.9737 10.884 18.4079 11.4497L12.4339 17.4241C12.1941 17.6617 11.8141 17.6617 11.5744 17.4241L5.60034 11.45ZM22.7925 16.9944V21.7128C22.7925 22.301 22.3088 22.7844 21.7208 22.7844H2.28713C1.69916 22.7844 1.21549 22.301 1.21549 21.7128V16.9944C1.21549 16.1942 0 16.1942 0 16.9944V21.7128C0 22.9717 1.02789 23.9999 2.28713 23.9999H21.7208C22.9798 23.9999 24.0079 22.9717 24.0079 21.7128V16.9944C24.0079 16.1942 22.7925 16.1942 22.7925 16.9944Z" fill="black" />
            </svg>

        </>
    )
}
