export default function BlackButton({
  buttonText,
  onClickFunction,
  bgColor = "bgBlack",
}) {
  // const [name, setterFunction] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);
  // const functionName = () => {
  //   setterFunction(!name);
  // };

  return (
    <button
      className={`min-w-32 bg-${bgColor} border border-white border-opacity-20 py-2 px-4 rounded-xl cursor-pointer font-semibold`}
      onClick={onClickFunction}
    >
      {buttonText}
    </button>
  );
}
