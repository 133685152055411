export default function NavSystem({ isActive, activeColor }) {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1293 17.7054L19.6639 15.5972C19.4638 15.3106 19.3562 14.9697 19.3553 14.6202V8.56989C19.3553 6.29702 18.4525 4.11723 16.8453 2.51006C15.2381 0.902897 13.0583 0 10.7855 0C8.51258 0 6.33279 0.902897 4.72563 2.51006C3.11846 4.11723 2.21556 6.29702 2.21556 8.56989V14.6202C2.21473 14.9697 2.10708 15.3106 1.90704 15.5972L0.441593 17.7054C0.219701 17.9513 0.073788 18.2561 0.021499 18.5832C-0.03079 18.9102 0.0127845 19.2454 0.146953 19.5481C0.281122 19.8509 0.500138 20.1084 0.777517 20.2893C1.0549 20.4703 1.37876 20.567 1.70994 20.5677H6.58621C6.7829 21.5364 7.30842 22.4073 8.07372 23.0328C8.83902 23.6583 9.79704 24 10.7855 24C11.7739 24 12.7319 23.6583 13.4972 23.0328C14.2625 22.4073 14.788 21.5364 14.9847 20.5677H19.861C20.1922 20.567 20.516 20.4703 20.7934 20.2893C21.0708 20.1084 21.2898 19.8509 21.424 19.5481C21.5581 19.2454 21.6017 18.9102 21.5494 18.5832C21.4971 18.2561 21.3512 17.9513 21.1293 17.7054ZM10.7855 22.2817C10.255 22.2802 9.73804 22.1147 9.30538 21.8079C8.87272 21.501 8.54555 21.0678 8.36875 20.5677H13.2022C13.0254 21.0678 12.6982 21.501 12.2655 21.8079C11.8329 22.1147 11.3159 22.2802 10.7855 22.2817ZM1.70994 18.8538C1.74867 18.8178 1.78318 18.7776 1.81278 18.7338L3.31251 16.5742C3.71259 16.001 3.92788 15.3192 3.92954 14.6202V8.56989C3.92954 6.75159 4.65186 5.00776 5.93759 3.72203C7.22332 2.4363 8.96715 1.71398 10.7855 1.71398C12.6038 1.71398 14.3476 2.4363 15.6333 3.72203C16.9191 5.00776 17.6414 6.75159 17.6414 8.56989V14.6202C17.643 15.3192 17.8583 16.001 18.2584 16.5742L19.7581 18.7338C19.7877 18.7776 19.8222 18.8178 19.861 18.8538H1.70994Z"
        fill={isActive ? activeColor : "white"}
      />
    </svg>
  );
}
