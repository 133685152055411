import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "../Components/Layout/Header";
import Sidebar from "../Components/Layout/Sidebar";

export default function Layout() {
  // const [name, setterFunction] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);
  // const functionName = () => {
  //   setterFunction(!name);
  // };
  return (
    <>
      <div className="relative overflow-hidden min-h-screen">
        <div className="absolute bg-lightGreenBlur rounded-full w-[50%] h-[90vh] -top-[60vh] -left-[25%] opacity-20 filter blur-3xl overflow-hidden"></div>
        <div className="absolute bg-lightGreenBlur h-[90vh] w-[50%] top-[6vh] left-[90%] rounded-full filter blur-3xl opacity-30"></div>
        <div className="absolute bg-lightBlueBlur h-[70vh] w-[70%] top-[70%] -left-[55%] rounded-full filter blur-3xl opacity-30"></div>

        <div className="flex gap-4 max-w-[1920px] mx-auto z-10 relative">
          <div className="max-w-[20%] ">
            <Sidebar />
          </div>
          <div className="flex-grow p-4 py-8 ">
            <Header />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
