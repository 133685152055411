import React, { useEffect, useState } from "react";
import GradientText from "../Common/GradientText";
import GradientButton from "../Buttons/GradientButton";
import BlackButton from "../Buttons/BlackButton";

export default function MiniHeader({
  leftSideText,
  nextText,
  nextFunction,
  backButtonVisible = true,
  backText,
  backFunction,
}) {
  // const [name, setterFunction] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);
  // const functionName = () => {
  //   setterFunction(!name);
  // };

  return (
    <div className="flex justify-between items-center align-middle gap-3 py-2 px-4 bg-bgBlack rounded-2xl border border-white border-opacity-20">
      <div className="font-bold text-4xl">
        <GradientText text={leftSideText} />
      </div>
      <div className="flex gap-2 justify-between items-center">
        {backButtonVisible > 0 && (
          <BlackButton buttonText={backText} onClickFunction={backFunction} />
        )}
        <GradientButton buttonText={nextText} onClickFunction={nextFunction} />
      </div>
    </div>
  );
}
