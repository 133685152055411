import React from 'react'

export default function DoNotSvg() {
    return (
        <>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.5 0C4.27871 0 0 4.27871 0 9.5C0 14.7213 4.27871 19 9.5 19C14.7213 19 19 14.7213 19 9.5C19 4.27871 14.7213 0 9.5 0ZM2.26367 9.5C2.26367 5.51442 5.51442 2.26367 9.5 2.26367C11.0029 2.26367 12.4502 2.73121 13.6859 3.61071L9.5 7.79664L3.61078 13.6859C2.73121 12.4502 2.26367 11.0029 2.26367 9.5ZM9.5 16.7363C7.99711 16.7363 6.54977 16.2687 5.31406 15.3892L15.3893 5.31403C16.2688 6.54977 16.7363 7.99703 16.7363 9.5C16.7363 13.4855 13.4856 16.7363 9.5 16.7363Z" fill="#FF0000" />
                <path d="M19 9.5C19 14.7213 14.7213 19 9.5 19V16.7363C13.4856 16.7363 16.7363 13.4855 16.7363 9.5C16.7363 7.99703 16.2688 6.54977 15.3892 5.31406L9.5 11.2033V7.79664L13.6859 3.61071C12.4502 2.73121 11.0029 2.26367 9.5 2.26367V0C14.7213 0 19 4.27871 19 9.5Z" fill="#FF0000" />
            </svg>

        </>
    )
}
