import React from "react";

export default function BookBtn() {
  return (
    <>
      <button className="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur mt-3 text-black font-bold w-[110px] h-[25px] rounded-3xl">
        Book
      </button>
    </>
  );
}
