import React, { useState } from "react";
import SelectIconGreen from "../../../Components/Svgs/SelectIconGreen";
import RequestSvg from "../../../Components/Svgs/RequestSvg";
import qrcode from "../../../Assets/Images/qr-code.png";
import TableDetails from "../../../Components/Modal/CreateNewTable/TableDetails";
import TableFeatures from "../../../Components/Modal/CreateNewTable/TableFeatures";
import Configurations from "../../../Components/Modal/CreateNewTable/Configurations";
import SeatCapacity from "../../../Components/Modal/CreateNewTable/SeatCapacity";
import TableDescription from "../../../Components/TableManagement/FloorManagement/TableDescription";
// import { useNavigate } from "react-router-dom";



export default function EditTable({toggleEditTableFalse}) {
  // const navigate = useNavigate();

  // function backtohome() {
  //   navigate("/");
  // }
  // const toggleVisibility = () => {
  //   setShowEditTable(!showEditTable);
  // };

  return (
    <>
    
    
      
        <div>
          {/* top bar */}
          <div className="md:w-[100%] p-2 bg-bgBlack border border-solid border-white border-opacity-20 rounded-3xl flex justify-between items-center">
            {/* Edit Table*/}
            <div className="sm:w-[25%] flex justify-between p-1">
              <h3 className="text-gradient-custom font-bold text-3xl custom-font">
                Edit Table
              </h3>
            </div>
            {/* buttons div */}
            <div className="sm:w-[90%] md:w-[60%] lg:w-[40%] xl:[30%] flex justify-center">
              <div className="bg-bgBlack w-[40%] mr-3 border border-solid border-white border-opacity-20 rounded-3xl text-white font-bold h-[50px] flex items-center justify-evenly">
                <RequestSvg />
                <p className="">Bulk Action </p>
                <SelectIconGreen />
              </div>
              <button onClick={toggleEditTableFalse} className="bg-bgBlack w-[30%] mr-3 border border-solid border-white border-opacity-20 rounded-3xl text-white font-bold h-[50px]" >
                Back
              </button>
              <button  onClick={toggleEditTableFalse} className="bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur w-[30%] mr-3 text-black font-bold h-[50px] border border-solid border-white border-opacity-20 rounded-3xl">
                Save
              </button>
            </div>
          </div>
          {/* Middle Section */}
          <div className="w-[100%] mb-5 p-1 flex justify-center mt-5 flex-wrap">
            {/* Providing Table Details */}
            <div className="sm:w-[90%] md:w-[45%] xl:w-[18%] mr-2 mb-3">
              <TableDetails />
            </div>
            {/* table features */}
            <div className="sm:w-[90%] md:w-[45%] xl:w-[20%] mr-2 mb-3">
              <TableFeatures />
            </div>
            {/* Optional configurations */}
            <div className="sm:w-[90%] md:w-[45%] xl:w-[20%] mr-2 mb-3">
              <Configurations />
            </div>
            {/* Seat Capacity */}
            <div className="sm:w-[90%] md:w-[45%] xl:w-[18%] mr-2 mb-3">
              <SeatCapacity />
            </div>
            {/* QR Code */}
            <div className="sm:w-[90%] md:w-[45%] xl:w-[20%] mb-3 p-3 bg-bgBlack border border-solid border-white border-opacity-20 rounded-3xl flex flex-col">
              <h3 className="text-gradient-custom font-bold text-3xl p-4 custom-font">
                QR Code
              </h3>
              <img src={qrcode} className="sm:w-[50%] md:w-[60%] xl:w-[153px] h-[153px] ml-3" alt="qrcode" />
              <button className="bg-gradient-to-br ml-3 from-lightGreenBlur to-lightBlueBlur mt-5 text-black font-bold md:w-[80%] xl:w-[177px] h-[60px] rounded-3xl custom-font">
                QR Code
              </button>
            </div>
          </div>
          {/* last section  */}
          {/* <div className="w-[100%] h-auto p-3 bg-bgBlack border border-solid border-white border-opacity-20 rounded-3xl flex flex-col items-center"> */}
            {/* header div */}
            {/* <div className="w-[100%] flex justify-between items-center mt-2 p-5 border-b border-solid border-white border-opacity-20">
              <div className="w-[50%] text-gradient-custom text-3xl font-bold custom-font">
                1. Floor Name
              </div>
              <div className="w-[50%] text-gradient-custom text-3xl font-bold text-right custom-font">
                Manage Table Location
              </div>
            </div> */}
            {/* description details */}
            {/* <div className="mt-5"> */}
              {/* <TableDescription showEditIcon={false} /> */}
            {/* </div> */}
          {/* </div> */}
        </div>
  
    </>
  );
} 
