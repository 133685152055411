import "./App.css";
import { Routes, Route } from "react-router-dom";
import Login from "./Views/Login/Login";
import Nothing from "./Views/Others/Nothing";
import Delete from "./Views/Others/Delete";
import Layout from "./Views/Layout";
import HomeDashboard from "./Views/HomeDashboard";
import LocationList from "./Views/TableManagement/Location/LocationList";
import FloorManagement from "./Views/TableManagement/FloorManagem/FloorManagement";
import Reservation from "./Views/TableReservation/Reservation";
import LocationManagement from "./Views/Management/Location/LocationManagement";
import AddLocation from "./Views/Management/Location/AddLocation";

function App() {
  return (
    <div className="bg-gradient-to-b from-bgGradientTop to-bgGradientBottom min-h-screen text-white w-full h-full font-Urbanist">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomeDashboard />} />
          <Route path="dashboard" element={<HomeDashboard />} />
          <Route path="manage/locations" element={<LocationManagement />} />
          <Route path="manage/locations/add" element={<AddLocation />} />
          <Route path="/manage/tables" element={<LocationList />} />
          <Route
            path="/manage/tables/:restaurantId"
            element={<FloorManagement />}
          />
          // DYNAMIC ROUTE - Floor MGMT
          <Route path="/manage/reservations" element={<Reservation />} />
          <Route path="*" element={<Nothing />} />
        </Route>

        <Route path="/delete" element={<Delete />} />
      </Routes>
    </div>
  );
}

export default App;
