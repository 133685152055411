import React from "react";
import SearchIcon from "../Svgs/SearchIcon";
import CalenderSvg from "../Svgs/CalenderSvg";
import WaitingSvg from "../Svgs/WaitingSvg";
import PersonSvg from "../Svgs/PersonSvg";
import ClockBlackSvg from "../Svgs/ClockBlackSvg";

export default function ReservationCheckIn() {
  const reservations = [
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
    {
      id: 1,
      date: "10/Feb/2024",
      time: "5:50 PM",
      name: "Mark Anderson",
      number: "+97 3333 222 555",
      tableNo: "T 24",
      persons: 4,
    },
  ];

  const handleCheckIn = (id) => {};

  return (
    <>
      <div className="  sm:w-[100%] md:w-[65%] lg:w-[100%] lg:space-x-0 lg:grid grid-cols-1 xl:w-[100%] xl:space-x-8 xl:flex justify-start">
        {/* Reservstion */}
        <div className=" sm:w-[100%] sm:flex-wrap h-auto  md:w-[100%] lg:w-[100%] xl:w-[38%] bg-bgBlack p-3 border border-solid border-white border-opacity-20 rounded-3xl mt-8 ">
          <div className="mx-3 py-4 px-2 border-b-2 border-white border-opacity-20">
            <h2 className=" font-normal text-lg font-family mt-2 py-2 border-r-2 border-white border-opacity-50 w-36">
              {" "}
              <span className="text-[#00FC92] pr-3">+</span> Reservation
            </h2>
          </div>

          <div className="mx-3 py-4 px-2  flex">
            <div className="flex items-center justify-normal">
              <CalenderSvg />
              <h2 className=" font-normal w-28 py-2 text-sm font-family border-r-2 border-white border-opacity-50  text-[#00FC92] ml-3">
                {" "}
                Reservation List
              </h2>
            </div>

            <div className="flex items-center justify-normal ml-3">
              <WaitingSvg />
              <h2 className=" font-normal w-[90px] py-2 text-sm font-family border-r-2 border-white border-opacity-50  ml-3">
                {" "}
                Waiting List
              </h2>
            </div>

            <div className="w-[30%] h-[44px] ml-16 border border-solid border-white border-opacity-20 rounded-xl flex justify-start items-center">
              <div className="p-3">
                <SearchIcon />
              </div>
              <div class="p-2">
                <input
                  type="text"
                  placeholder="Search"
                  class="bg-transparent text-white font-bold border-none outline-none placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          {/* Reservation List */}
          <table>
            <tbody>
              {reservations.map((reservation, index) => (
                <tr
                  key={index}
                  className="font-light mx-5 text-sm border-b-2 border-white border-opacity-20 py-3"
                >
                  <td className="px-4 py-4">
                    <p>{reservation.date}</p>
                    <p className="text-[18px] font-normal">
                      {reservation.time}
                    </p>
                  </td>

                  <td className="px-4 ">
                    <p>{reservation.name}</p>
                    <p className="text-gray-400">{reservation.number}</p>
                  </td>

                  <td className="px-4">
                    <p>Table Number</p>
                    <p className="text-gray-400">{reservation.tableNo}</p>
                  </td>

                  <td className="px-4  py-4 flex items-center">
                    <PersonSvg />
                    <p className="ml-2">{reservation.persons}</p>
                  </td>
                  <td className="px-4">
                    <button
                      className="w-fit px-4 cursor-pointer text-center text-sm bg-gradient-to-br from-lightGreenBlur to-lightBlueBlur text-black font-medium border border-solid border-white border-opacity-20 rounded-2xl"
                      onClick={() => handleCheckIn(reservation.id)}
                    >
                      Check In
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* CheckedIn */}
        <div className="sm:w-[100%] sm:flex-wrap h-auto  md:w-[100%] lg:w-[100%] xl:w-[48%]  bg-bgBlack p-3 border border-solid border-white border-opacity-20 rounded-3xl mt-8 ">
          <div className=" py-4  border-b-2 border-white border-opacity-20 flex justify-between">
            <h2 className=" font-normal text-lg font-family mt-2 py-2 border-r-2 border-white border-opacity-50 w-36">
              {" "}
              <span className="text-[#00FC92] pr-3">+</span> Checked In
            </h2>
          </div>
          <div className="mx-3 py-4 px-2  flex justify-between">
            <div className="flex items-center justify-normal">
              <CalenderSvg />
              <h2 className=" font-normal w-28 py-2 text-sm font-family border-r-2 border-white border-opacity-50  text-[#00FC92] ml-3">
                {" "}
                Checked In
              </h2>
            </div>

            <div className="w-[40%] h-[44px] ml-28 border border-solid border-white border-opacity-20 rounded-xl flex justify-start items-center">
              <div className="p-3">
                <SearchIcon />
              </div>
              <div class="p-2">
                <input
                  type="text"
                  placeholder="Search"
                  class="bg-transparent text-white font-bold border-none outline-none placeholder-opacity-0"
                />
              </div>
            </div>
          </div>
          <table className="w-full">
            <tbody>
              {reservations.map((reservation, index) => (
                <tr
                  key={index}
                  className="font-light mx-5 text-sm border-b-2 border-white border-opacity-20 py-3"
                >
                  <td className="px-4 py-4">
                    <p>{reservation.date}</p>
                    <p className="text-[18px] font-normal">
                      {reservation.time}
                    </p>
                  </td>

                  <td className="px-4">
                    <p>{reservation.name}</p>
                    <p className="text-gray-400">{reservation.number}</p>
                  </td>

                  <td className="px-4">
                    <p>Table Number</p>
                    <p className="text-gray-400">{reservation.tableNo}</p>
                  </td>

                  <td className="px-4 py-4 flex items-center">
                    <PersonSvg />
                    <p className="ml-2">{reservation.persons}</p>
                  </td>

                  <td className="px-4">
                    <div>
                      <p className="text-center pb-1 text-gray-300">
                        Time Spent
                      </p>
                      <div className="w-fit px-2 h-7 py-1 cursor-pointer text-center text-sm bg-lightGreenBlur font-medium border border-solid border-white border-opacity-20 rounded-2xl flex justify-evenly items-center">
                        <ClockBlackSvg />
                        <p className="text-black ml-1">50m 30s</p>
                      </div>
                    </div>
                  </td>

                  <td className="px-4">
                    <div>
                      <p className="text-center pb-1 text-gray-300">
                        Time Left
                      </p>
                      <div className="w-fit px-2 h-7 py-1 cursor-pointer text-center text-sm bg-lightBlueBlur font-medium border border-solid border-white border-opacity-20 rounded-2xl flex justify-evenly items-center">
                        <ClockBlackSvg />
                        <p className="text-black ml-1">50m 30s</p>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
                    
        </div>
      </div>
              
    </>
  );
}
