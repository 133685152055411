import GradientText from "../Common/GradientText";
export default function AddNewButton() {
  return (
    <div className=" bg-gradient-to-r from-lightGreenBlur to-lightBlueBlur text-xl cursor-pointer font-bold  rounded-2xl p-[1px]">
      <div className="bg-bgBlack py-2 px-4 inline-flex items-center gap-2 rounded-2xl">
        <div className="w-10 h-10  bg-gradient-to-r from-lightGreenBlur to-lightBlueBlur rounded-full flex justify-center items-center">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.06254 9.5V5.936H0.498535V4.064H4.06254V0.5H5.93454V4.064H9.49854V5.936H5.93454V9.5H4.06254Z"
              fill="#0E1D29"
            />
          </svg>
        </div>
        <GradientText text="Add New" />
      </div>
    </div>
  );
}
