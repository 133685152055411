import React, { useEffect, useState } from "react";

export default function AddInputField({ label, name, placeholder }) {
  return (
    <div>
      <label className="font-semibold text-xl">{label}</label>
      {/* <div className="relative pt-4 group">
        <input
          className="bg-inherit font-semibold focus-visible:border-none focus-visible:outline-none w-full placeholder:font-normal"
          type="text"
          name={name}
          placeholder={placeholder}
        />
        <div className="absolute inset-0 bg-white border-none pt-[1px] w-full mt-4 transition-colors duration-300 ease-in-out group-focus:bg-blue-200"></div>
      </div> */}

      <div className=" relative pt-4 mb-8 group">
        <input
          className="bg-inherit font-semibold focus-visible:outline-none w-full placeholder:font-normal"
          type="text"
          name={name}
          placeholder={placeholder}
        />
        <div className=" absolute -bottom-4 left-0 border-none pt-[1px] w-full bg-white group-focus-within:bg-gradient-to-r group-focus-within:from-lightGreenBlur group-focus-within:to-lightBlueBlur"></div>
      </div>
    </div>
  );
}
