import React, { useEffect, useState } from "react";

export default function TotalLocationsChart() {
  // const [name, setterFunction] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);
  // const functionName = () => {
  //   setterFunction(!name);
  // };
  return (
    <div className="h-56 bg-bgBlack p-4 rounded-2xl border border-opacity-20 border-white ">
      <p> TotalLocationsChart</p>
    </div>
  );
}
