import React from 'react'

export default function ClockBlackSvg() {
  return (
<>
<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1271_7650)">
<path d="M6.99961 14.15C3.33273 14.15 0.349609 11.1669 0.349609 7.49998C0.349609 3.8331 3.33273 0.849976 6.99961 0.849976C10.6665 0.849976 13.6496 3.8331 13.6496 7.49998C13.6496 11.1669 10.6665 14.15 6.99961 14.15ZM6.99961 1.48334C3.68203 1.48334 0.982969 4.1824 0.982969 7.49998C0.982969 10.8176 3.68203 13.5166 6.99961 13.5166C10.3172 13.5166 13.0162 10.8176 13.0162 7.49998C13.0162 4.1824 10.3172 1.48334 6.99961 1.48334Z" fill="black"/>
<path d="M10.0955 7.81669L6.9993 7.81669C6.91531 7.81669 6.83476 7.78333 6.77537 7.72394C6.71598 7.66455 6.68262 7.584 6.68262 7.50001L6.68262 2.96121C6.68262 2.87722 6.71598 2.79667 6.77537 2.73728C6.83476 2.6779 6.91531 2.64453 6.9993 2.64453C7.08329 2.64453 7.16383 2.6779 7.22322 2.73728C7.28261 2.79667 7.31598 2.87722 7.31598 2.96121L7.31598 7.18333L10.0955 7.18333C10.1795 7.18333 10.2601 7.21669 10.3195 7.27608C10.3789 7.33547 10.4122 7.41602 10.4122 7.50001C10.4122 7.584 10.3789 7.66455 10.3195 7.72394C10.2601 7.78333 10.1795 7.81669 10.0955 7.81669Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_1271_7650">
<rect width="14" height="14" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>



</>  

)
}
