import React from "react";
import CrossSvg from "../Svgs/CrossSvg";
import GradientButton from "../Buttons/GradientButton";
import { useState } from "react";

export default function CreateNewReservation({
  newReservation,
  setNewReservation,
}) {
  const toggleVisibility = () => {
    setNewReservation(!newReservation);
  };

  return (
    <>
      {newReservation && (
        <div className="w-screen h-screen fixed top-0 left-0  bg-[#000000C7] p-3 border border-solid border-white border-opacity-20 rounded-3xl ">
          <div className="bg-bgBlack w-[20%] h-auto border border-solid border-white border-opacity-20 rounded-3xl absolute top-24  md:left-[63%] lg:left-[58%] xl:left-[78%]">
            <div className="flex justify-between mx-8 py-5 ">
              <div className="flex justify-between w-[100%]">
                <h2 className=" font-normal text-xl font-family my-2">
                  Reservation
                </h2>
                <div onClick={toggleVisibility} className="cursor-pointer">
                  <CrossSvg />
                </div>
              </div>
            </div>
            <div>
              <form>
                <div className="pb- border-b-[2px] border-solid border-white border-opacity-20 mx-8">
                  <label
                    for="first_name"
                    className=" font-medium text-xl font-family text-white"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="table-no"
                    className=" w-[100%] font-normal bg-transparent text-xl text-gray-200 pt-2  pb-3 mt-2"
                    placeholder="Enter Your Name Here"
                    required
                  />
                </div>

                <div className="pb- border-b-[2px] border-solid border-white border-opacity-20 mx-8 mt-5">
                  <label
                    for="Phone_no"
                    className=" font-medium text-xl font-family text-white"
                  >
                    Mobile number
                  </label>
                  <input
                    type="tel"
                    id="table-no"
                    className=" w-[100%] font-normal bg-transparent text-xl text-gray-200 pt-2 pb-3 mt-2"
                    placeholder="type here"
                    required
                  />
                </div>

                <div className="pb- border-b-[2px] border-solid border-white border-opacity-20 mx-8 mt-5">
                  <label
                    for="Person"
                    className=" font-medium text-xl font-family text-white"
                  >
                    Person
                  </label>
                  <input
                    type="number"
                    id="table-no"
                    className=" w-[100%] font-normal bg-transparent text-xl text-gray-200 pt-2 pb-3 mt-2"
                    placeholder="00"
                    required
                  />
                </div>

                <div className="mx-8 mt-5">
                  <p className=" font-medium text-xl font-family text-white">
                    {" "}
                    Time
                  </p>

                  <div className=" my-5 flex space-x-4">
                    <div className="flex flex-col space-y-2">
                      <div className=" bg-gradient-to-r from-lightGreenBlur to-lightBlueBlur cursor-pointer  rounded-xl p-[1px]">
                        <div className="w-16 h-16 bg-gray-600 py-2 px-4 inline-flex items-center justify-center rounded-xl">
                          <p className="text-lg text-gray-200">00</p>
                        </div>
                      </div>
                      <p className="ml-2 text-sm">Hour</p>
                    </div>

                    <p className="text-xl font-bold pt-4">:</p>

                    <div className="flex flex-col space-y-2">
                      <div className=" bg-gradient-to-r from-lightGreenBlur to-lightBlueBlur cursor-pointer  rounded-xl p-[1px]">
                        <div className="w-16 h-16 bg-gray-600 py-2 px-4 inline-flex items-center justify-center rounded-xl">
                          <p className="text-lg text-gray-200">00</p>
                        </div>
                      </div>
                      <p className="ml-3 text-sm">Min</p>
                    </div>

                    <div className="w-16 h-16  bg-gray-600 flex flex-col rounded-xl text-center overflow-hidden">
                      <p className="text-lg text-gray-200 pt-1  bg-gradient-to-r from-lightGreenBlur to-lightBlueBlur ">
                        AM
                      </p>
                      <p className="text-lg text-gray-200 p">PM</p>
                    </div>
                  </div>

                  <div className=" flex items-center mt-8 mb-16">
                    <input
                      id="green"
                      name="color"
                      color="green"
                      type="radio"
                      value=""
                      class="w-7 h-7 rounded-xl"
                    />
                    <label
                      for="list-radio-passport"
                      className="w-full py-3 ms-2 text-xl font-medium  "
                    >
                      Add To Waiting List
                    </label>
                  </div>
                </div>

                <div className="flex justify-center mb-8 ">
                  <GradientButton
                    buttonText="Add"
                    width="w-[90%]"
                    backgroundColor="bg-[#00FC92]"
                    border="rounded-2xl"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
