import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateHeader } from "../../../store/slices/headerSlice";
import TabDiv from "../../../Components/Buttons/TabDiv";
import TextWithNumber from "../../../Components/Common/TextWithNumber";
import LocationTable from "../../../Components/Tables/LocationTable";
import BulkActionButton from "../../../Components/Buttons/BulkActionButton";
import AddNewButton from "../../../Components/Buttons/AddNewButton";

export default function LocationManagement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  // console.log("passwordVisible>>>", passwordVisible);

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const BulkActionOptions = [
    {
      value: 1,
      label: "one",
      onClickFunction: () => console.log("one"),
    },
    {
      value: 2,
      label: "two",
      onClickFunction: () => console.log("two"),
    },
    {
      value: 3,
      label: "three",
      onClickFunction: () => console.log("three"),
    },
    {
      value: 4,
      label: "four",
      onClickFunction: () => console.log("four"),
    },
  ];

  useEffect(() => {
    const headerData = {
      title: "Locations Management",
      breadCrumbs: [
        {
          name: "manage",
          link: "/manage/locations",
        },
        {
          name: "locations",
          link: "/manage/locations",
        },
      ],
    };

    dispatch(updateHeader(headerData));
  });

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <TabDiv
            divText="Active Locations"
            isActive={activeTab === 0}
            onClickFunction={() => changeActiveTab(0)}
          />
          <TabDiv
            divText="Draft Locations"
            isActive={activeTab === 1}
            onClickFunction={() => changeActiveTab(1)}
          />
        </div>
        <div className="flex gap-2">
          <div>
            <BulkActionButton options={BulkActionOptions} />{" "}
          </div>
          <div onClick={() => navigate("/manage/locations/add")}>
            <AddNewButton />{" "}
          </div>
        </div>
      </div>
      <div className="flex gap-3">
        <TextWithNumber
          leftSideText="Total Number of Registered Locations"
          rightSideText="400"
        />
        <TextWithNumber
          leftSideText="Number of Active Locations"
          rightSideText="160"
        />
        <TextWithNumber
          leftSideText="Number of Pending Approvals"
          rightSideText="50"
        />
      </div>
      <LocationTable />
    </div>
  );
}
