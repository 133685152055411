import GradientText from "./GradientText";

export default function TextWithNumber({ leftSideText, rightSideText }) {
  return (
    <div className="flex max-w-[250px] gap-2 py-4 px-4 border border-white rounded-3xl border-opacity-20 justify-between items-center align-middle bg-bgBlack">
      <div className=" font-semibold"> {leftSideText}</div>
      <div className="font-extrabold text-3xl text-right">
        {" "}
        <GradientText text={rightSideText} />
      </div>
    </div>
  );
}
