import React from "react";
import ClockSvg from "../../Svgs/ClockSvg";
import BillSvg from "../../Svgs/BillSvg";

export default function ButtonTableDetails() {
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="border-[3px]  border-solid border-green-500 border-opacity-20 rounded-2xl mt-3 w-[110px] h-[25px] text-gradient-custom flex justify-evenly items-center mr-2">
          <ClockSvg />
          <p className="text-gradient-custom ">50m 30s</p>
        </div>

        <div className="border-[3px]  border-solid border-green-500 border-opacity-20 rounded-2xl mt-3 w-[110px] h-[25px] text-gradient-custom flex justify-evenly items-center mr-2">
          <BillSvg />
          <p className="text-gradient-custom ">50m 30s</p>
        </div>
      </div>
    </>
  );
}
