export default function TabDiv({ divText, isActive, onClickFunction }) {
  return (
    <div
      className={`${
        isActive
          ? " text-lg  bg-gradient-to-r from-lightGreenBlur to-lightBlueBlur text-buttonBlackText font-bold "
          : " text-xl bg-bgBlack"
      } border border-white border-opacity-20 py-2 px-3 rounded-xl cursor-pointer`}
      onClick={onClickFunction}
    >
      {divText}
    </div>
  );
}
