import React from "react";

export default function EditSvg({ clickFunction, isEditPage }) {

  if (isEditPage) return null;

  return (
    <div className="cursor-pointer font-bold" onClick={clickFunction}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          opacity="0.05"
          cx="9.00439"
          cy="8.79736"
          r="8.34033"
          fill="#00FC92"
        />
        <path
          d="M12.6331 6.73076C12.7956 6.56826 12.7956 6.29743 12.6331 6.14326L11.6581 5.16826C11.5039 5.00576 11.2331 5.00576 11.0706 5.16826L10.3039 5.93076L11.8664 7.49326M5.25391 10.9849V12.5474H6.81641L11.4247 7.93493L9.86224 6.37243L5.25391 10.9849Z"
          fill="#00FC92"
        />
      </svg>
    </div>
  );
}
