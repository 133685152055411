import React, { useEffect, useState } from "react";
import GradientText from "../../Common/GradientText";
import AddInputField from "../../Common/AddInputField";
import locationMap from "../../../Assets/Images/locationMap.png";

export default function ALS1() {
  // const [name, setterFunction] = useState(false);
  // console.log("passwordVisible>>>", passwordVisible);

  return (
    <div className="space-y-5">
      <div className="flex gap-3 items-start">
        <div className="space-y-3 py-5 px-8 bg-bgBlack rounded-3xl w-1/4 h-auto max-h-fit">
          <div className="font-semibold text-2xl">
            <GradientText text="Location Information" />
          </div>
          <div className="space-y-5">
            <AddInputField
              label="Restaurant name"
              name="restaurantName"
              placeholder="type here"
            />
            <AddInputField
              label="Legal Name"
              name="legalName"
              placeholder="type here"
            />
            <AddInputField
              label="Short Description"
              name="shortDescription"
              placeholder="type here"
            />
          </div>
        </div>
        <div className="space-y-3 py-5 px-8 bg-bgBlack rounded-3xl w-1/4">
          <div className="font-semibold text-2xl">
            <GradientText text="Address details" />
          </div>
          <div className="space-y-5">
            <AddInputField
              label="Country"
              name="country"
              placeholder="type here"
            />
            <AddInputField label="City" name="city" placeholder="type here" />
            <AddInputField
              label="Street Name"
              name="streetName"
              placeholder="type here"
            />
            <AddInputField
              label="Select Location"
              name="selectLocation"
              placeholder="type here"
            />
            <AddInputField
              label="Building Name"
              name="buildingName"
              placeholder="type here"
            />
          </div>
        </div>{" "}
      </div>
      <div className="rounded-3xl overflow-hidden h-64">
        <img
          src={locationMap}
          alt="locationMap"
          className="object-cover object-center h-full w-full"
        />
      </div>
    </div>
  );
}
