import React from "react";
import resturantimage from "../../../Assets/Images/Rectangle.png";
import Items from "../../../utils/dummyData/Dummydata";
export default function ResturantLocation({ onFloorManagementClick }) {
  function GotoFloorManage() {
    onFloorManagementClick();
  }

  const { Location } = Items;
  return (
    <>
      <div className="w-[100%] h-auto flex justify-center space-x-4 mt-4 flex-wrap">
        {Location.map((restaurant, index) => (
          <div
            key={index}
            className="sm:ml-3 sm:w-[95%] h-auto md:w-[48%] lg:w-[32%] mb-5  bg-bgBlack p-3 border border-solid border-white border-opacity-20 rounded-3xl "
          >
            <img src={resturantimage} className="mt-2" alt="restaurant" />
            <h3 className="mt-4">{restaurant.name}</h3>
            <h6 className="mt-3">Description</h6>
            <p className="mt-3">{restaurant.description}</p>
            <div className="flex justify-between mt-4">
              <p>
                <span className="text-gray-500">UID:</span> {restaurant.id}
              </p>
              <p>
                <span className="text-gray-500">City:</span> {restaurant.city}
              </p>
            </div>
            <button
              onClick={GotoFloorManage}
              className="bg-lightGreenBlur mt-5 text-black font-bold w-[98%] h-[50px] rounded-3xl"
            >
              View
            </button>
          </div>
        ))}
      </div>
    </>
  );
}
