import React, { useState } from "react";
import BulkActionSvg from "../Svgs/BulkActionSvg";
import { IoMdArrowDropdown } from "react-icons/io";

export default function BulkActionButton({ options }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (onClickFunction) => {
    // setSelectedOption(value);
    setIsOpen(false);
    onClickFunction();
  };

  return (
    <div className="relative inline-flex items-center">
      <div
        onClick={toggleOptions}
        className="relative bg-bgBlack border border-white border-opacity-20 rounded-2xl px-4 py-4 pl-10 pr-11 shadow-sm cursor-pointer font-semibold"
      >
        {selectedOption || "Bulk Action"}
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          Your custom SVG for the arrow mark
        </svg> */}
        <BulkActionSvg />

        <IoMdArrowDropdown className="h-5 w-5 absolute right-2 top-1/2 transform -translate-y-1/2 text-lightGreenBlur pointer-events-none" />
      </div>
      {isOpen && (
        <div className="absolute mt-52 w-full rounded-2xl bg-bgBlack shadow-lg border border-white border-opacity-20">
          {Array.isArray(options) &&
            options.length > 0 &&
            options.map((option) => (
              <div
                key={option.value}
                onClick={() => handleOptionClick(option.onClickFunction)}
                className="cursor-pointer px-4 py-2 hover:text-lightGreenBlur"
              >
                {option.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
